// @ts-nocheck
import { useLocationAdminFindById, useLocationFindById } from '@/api'
import { KBSelect } from '@/components/atoms/KBSelect'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { cn } from '@/utils/tailwind.helper'
import { isUserClient } from '@/utils/userEnvironment'
import dayjs, { Dayjs, findClosestMinute } from '@wox/dayjs'
import { TimePickerProps } from 'antd'
import { Variant } from 'antd/es/form/hooks/useVariants'
export interface KBTimeSelectPickerProps extends TimePickerProps {
  value?: Dayjs
  onChange: (value: Dayjs) => void
  step?: number
  startTimeForRange?: Dayjs
  selectVariant?: Variant
  format?: 'HH:mm' | 'LT'
  showDuration?: boolean
}

export interface KBTimeSelectPickerOptionItem {
  label: string
  value: number
  disabled?: boolean
}

export type KBTimeSelectPickerOptions = KBTimeSelectPickerOptionItem[]

/**
 * 计算两个时间之间的差距，并以特定格式返回。
 *
 * @param startTime 开始时间，可以是 Date 对象或日期时间字符串。
 * @param endTime 结束时间，可以是 Date 对象或日期时间字符串。
 * @returns 返回格式化的时间差字符串，形式为 `${小时}H${分钟}Min`。
 */
export function formatTimeDifference(
  startTime: Dayjs,
  endTime: Dayjs
): [number, number] {
  // 计算时间差，单位为分钟
  const totalMinutes = endTime.diff(startTime, 'minute')

  // 计算小时数和剩余分钟数
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  // 返回'[间隔的小时数,间隔的分钟数]'
  return [hours, minutes]
}

function getStringForRange(hours?: number, minutes?: number) {
  let hoursString = ''
  let minutesString = ''
  if (hours && hours > 0) {
    hoursString = `${hours}H`
  }
  if (minutes && minutes > 0) {
    minutesString = `${minutes}M`
  }
  return [hoursString, minutesString].join(' ')
}

function getTimeOptions(
  step: number,
  startTimeForRange?: Dayjs,
  formAt?: 'HH:mm' | 'LT',
  showDuration?: boolean
): KBTimeSelectPickerOptions {
  const startTime = dayjs(startTimeForRange).startOf('day')
  const endTime = dayjs(startTimeForRange).endOf('day')
  let stepTime = startTime

  const timeOptions: KBTimeSelectPickerOptions = []
  do {
    const [hours, minutes] = startTimeForRange
      ? formatTimeDifference(startTimeForRange, stepTime)
      : []
    const stringForRange = startTimeForRange
      ? getStringForRange(hours, minutes)
      : undefined
    const showDurationOption = showDuration && stringForRange
    timeOptions.push({
      // label: `${stepTime.format('LT')}${stringForRange ? `(${stringForRange})` : ''}`,
      label: `${stepTime.format(formAt)}${showDurationOption ? `(${stringForRange})` : ''}`,
      value: stepTime.diff(startTime, 'second'),
      disabled: hours < 0 || minutes < 0,
    })
    stepTime = stepTime.add(step, 'minute')
  } while (stepTime.isBefore(endTime))

  /**
   * 处理23:59
   */
  const [hours, minutes] = startTimeForRange
    ? formatTimeDifference(startTimeForRange, stepTime)
    : []
  const stringForRange = getStringForRange(hours, minutes)
  const showDurationOption = showDuration && stringForRange
  timeOptions.push({
    // label: `${stepTime.subtract(1, 'second').format('LT')}${stringForRange ? `(${stringForRange})` : ''}`,
    label: `${endTime.format(formAt)}${showDurationOption ? `(${stringForRange})` : ''}`,
    value: endTime.diff(startTime, 'second'),
    disabled: hours < 0 || minutes < 0,
  })
  return timeOptions
}

export function KBTimeSelectPicker(props: KBTimeSelectPickerProps) {
  const {
    step: _step,
    onChange,
    value, // value in dayjs
    startTimeForRange,
    placeholder = tr('common.selectTime'),
    className,
    selectVariant = 'outlined',
    format = 'LT',
    showDuration = false,
    ...otherProps
  } = props

  // const minutes = findClosestMinute(value, step) // value.diff(value?.startOf('day'), 'minute') //
  const [inValue, setInValue] = useState()

  const currentLocationId = useCurrentLocationId()
  const { data: locationData } = (
    isUserClient() ? useLocationFindById : useLocationAdminFindById
  )(currentLocationId!, {
    query: {
      enabled: Boolean(currentLocationId),
    },
  })

  const step = useMemo(() => {
    return _step || locationData?.time_interval || 30
  }, [_step, locationData])

  const handleChange = (value: number) => {
    const startTime = dayjs().startOf('day')
    setInValue(value)
    onChange(startTime.add(value, 'second'))
  }
  const options = useMemo(
    () => getTimeOptions(step, startTimeForRange, format, showDuration),
    [startTimeForRange, step]
  )
  // console.log('options', options)
  useEffect(() => {
    if (!value) {
      setInValue(undefined)
    } else {
      const seconds = value?.isSame(value.endOf('day'), 'second')
        ? value.diff(value?.startOf('day'), 'second')
        : findClosestMinute(value, step) * 60
      setInValue(seconds)
    }
  }, [value])

  const showDurationOption = showDuration && startTimeForRange

  return (
    <KBSelect
      className={cn(showDurationOption ? 'tw-w-40' : 'tw-w-32', className)}
      value={inValue}
      placeholder={placeholder}
      onChange={handleChange}
      options={options}
      variant={selectVariant}
      {...otherProps}
    />
  )
}
