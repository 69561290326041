// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { VisitorConfigFindByLocationIdQueryResponse, VisitorConfigFindByLocationIdQueryParams, VisitorConfigFindByLocationId401 } from "../models/VisitorConfigFindByLocationId";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type VisitorConfigFindByLocationIdClient = typeof client<VisitorConfigFindByLocationIdQueryResponse, VisitorConfigFindByLocationId401, never>;
type VisitorConfigFindByLocationId = {
    data: VisitorConfigFindByLocationIdQueryResponse;
    error: VisitorConfigFindByLocationId401;
    request: never;
    pathParams: never;
    queryParams: VisitorConfigFindByLocationIdQueryParams;
    headerParams: never;
    response: VisitorConfigFindByLocationIdQueryResponse;
    client: {
        parameters: Partial<Parameters<VisitorConfigFindByLocationIdClient>[0]>;
        return: Awaited<ReturnType<VisitorConfigFindByLocationIdClient>>;
    };
};
export const visitorConfigFindByLocationIdQueryKey = (params: VisitorConfigFindByLocationId["queryParams"]) => ["api", "v5", "visitor_configs", "find-by-location-id", ...(params ? [params] : [])] as const;
export type VisitorConfigFindByLocationIdQueryKey = ReturnType<typeof visitorConfigFindByLocationIdQueryKey>;
export function visitorConfigFindByLocationIdQueryOptions(params: VisitorConfigFindByLocationId["queryParams"], options: VisitorConfigFindByLocationId["client"]["parameters"] = {}) {
    const queryKey = visitorConfigFindByLocationIdQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<VisitorConfigFindByLocationId["data"], VisitorConfigFindByLocationId["error"]>({
                method: "get",
                url: `/api/v5/visitor_configs/find-by-location-id`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取VisitorConfigEntity详情
 * @link /api/v5/visitor_configs/find-by-location-id
 */
export function useVisitorConfigFindByLocationId<TData = VisitorConfigFindByLocationId["response"], TQueryData = VisitorConfigFindByLocationId["response"], TQueryKey extends QueryKey = VisitorConfigFindByLocationIdQueryKey>(params: VisitorConfigFindByLocationId["queryParams"], options: {
    query?: Partial<QueryObserverOptions<VisitorConfigFindByLocationId["response"], VisitorConfigFindByLocationId["error"], TData, TQueryData, TQueryKey>>;
    client?: VisitorConfigFindByLocationId["client"]["parameters"];
} = {}): UseQueryResult<TData, VisitorConfigFindByLocationId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? visitorConfigFindByLocationIdQueryKey(params);
    const query = useQuery({
        ...visitorConfigFindByLocationIdQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, VisitorConfigFindByLocationId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const visitorConfigFindByLocationIdSuspenseQueryKey = (params: VisitorConfigFindByLocationId["queryParams"]) => ["api", "v5", "visitor_configs", "find-by-location-id", ...(params ? [params] : [])] as const;
export type VisitorConfigFindByLocationIdSuspenseQueryKey = ReturnType<typeof visitorConfigFindByLocationIdSuspenseQueryKey>;
export function visitorConfigFindByLocationIdSuspenseQueryOptions(params: VisitorConfigFindByLocationId["queryParams"], options: VisitorConfigFindByLocationId["client"]["parameters"] = {}) {
    const queryKey = visitorConfigFindByLocationIdSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<VisitorConfigFindByLocationId["data"], VisitorConfigFindByLocationId["error"]>({
                method: "get",
                url: `/api/v5/visitor_configs/find-by-location-id`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取VisitorConfigEntity详情
 * @link /api/v5/visitor_configs/find-by-location-id
 */
export function useVisitorConfigFindByLocationIdSuspense<TData = VisitorConfigFindByLocationId["response"], TQueryKey extends QueryKey = VisitorConfigFindByLocationIdSuspenseQueryKey>(params: VisitorConfigFindByLocationId["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<VisitorConfigFindByLocationId["response"], VisitorConfigFindByLocationId["error"], TData, TQueryKey>>;
    client?: VisitorConfigFindByLocationId["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, VisitorConfigFindByLocationId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? visitorConfigFindByLocationIdSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...visitorConfigFindByLocationIdSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, VisitorConfigFindByLocationId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}