import { KBButton, KBFlex } from '@/components/atoms'
import { RecurrenceByMonthInterval } from '@/components/organisms/KBRecurrenceEditModal/constants'
import {
  KBRecurrenceEditModalProps,
  RecurrenceInfo,
} from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import dayjs, { nameOfDay } from '@wox/dayjs'
import { FunctionComponent } from 'react'

interface KBRecurrenceByMonthProps extends KBRecurrenceEditModalProps {
  recurrenceInfo: RecurrenceInfo
  setRecurrenceInfo: (info: RecurrenceInfo) => void
}

const KBRecurrenceByMonth: FunctionComponent<KBRecurrenceByMonthProps> = (
  props
) => {
  const { recurrenceInfo, setRecurrenceInfo } = props
  const startTime = recurrenceInfo.startDate || dayjs()

  return (
    <KBFlex justify="flex-start" className="tw-mt-3 tw-pl-[88px]" gap={6}>
      <KBButton
        type={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.day
            ? 'primary'
            : 'text'
        }
        className={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.day
            ? ''
            : 'tw-bg-[var(--wox-color-fill-tertiary)]'
        }
        onClick={() => {
          // setAllValues({
          //   ...allValues,
          //   monthDay: startTime.date(),
          //   monthWeekNumber: undefined,
          //   monthWeekDay: undefined,
          // })
          setRecurrenceInfo({
            ...recurrenceInfo,
            monthDay: startTime.date(),
            monthWeekNumber: undefined,
            monthWeekDay: undefined,
            intervalType: recurrenceInfo.intervalType,
            monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.day,
          })
        }}
      >
        {tr('recurrence.monthDay', { date: startTime.date() })}
      </KBButton>
      <KBButton
        type={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.week
            ? 'primary'
            : 'text'
        }
        className={
          recurrenceInfo.monthRecurrenceByMonthInterval ===
          RecurrenceByMonthInterval.week
            ? ''
            : 'tw-bg-[var(--wox-color-fill-tertiary)]'
        }
        onClick={() => {
          setRecurrenceInfo({
            ...recurrenceInfo,
            monthDay: undefined,
            monthWeekNumber:
              startTime.isoWeek() - startTime.startOf('month').isoWeek(),
            monthWeekDay: startTime.isoWeekday() - 1,
            intervalType: recurrenceInfo.intervalType,
            monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.week,
          })
        }}
      >
        {tr('recurrence.monthWeekDay', {
          no: startTime.isoWeek() - startTime.startOf('month').isoWeek(),
          weekDay: nameOfDay(startTime.weekday(), {
            weekdayFormat: 'short',
          }),
        })}
      </KBButton>
    </KBFlex>
  )
}

export default KBRecurrenceByMonth
