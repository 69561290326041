import { LocationLayout } from '@/components/layouts/LocationLayout'
import { VisitorLayout } from '@/components/layouts/VisitorLayout'
import { lazyLoad } from '@/utils/lazyLoad'
import { lazy } from 'react'
import { Navigate, Outlet, RouteObject } from 'react-router-dom'

// Lazy load components
const AnnouncementList = lazy(
  () => import('@/pages/admin/announcements/AnnouncementList')
)
const ApprovalDetail = lazy(
  () => import('@/pages/admin/approvals/ApprovalDetail')
)
const ApprovalList = lazy(() => import('@/pages/admin/approvals/ApprovalList'))
const AllAppsPage = lazy(() => import('@/pages/admin/apps/AllAppsPage'))
const InstalledApplications = lazy(
  () => import('@/pages/admin/apps/InstalledAppsPage')
)
const AppDetails = lazy(
  () => import('@/pages/admin/apps/components/AppDetails')
)
const WebhooksLayout = lazy(() => import('@/pages/admin/apps/webhooks/layout'))
const AreasListPage = lazy(() => import('@/pages/admin/areas/AreasListPage'))
const AuditLogsPage = lazy(
  () => import('@/pages/admin/auditLogs/AuditLogsPage')
)
const BillingPage = lazy(() => import('@/pages/admin/billing/BillingPage'))
const DashboardPage = lazy(() => import('@/pages/admin/dashboard'))
const DataStatisticDeliveryPage = lazy(
  () =>
    import('@/pages/admin/dataStatistics/deliveries/DataStatisticDeliveryPage')
)
const DataStatisticEmployeeWorkStatusPage = lazy(
  () =>
    import(
      '@/pages/admin/dataStatistics/employeeWorkStatus/DataStatisticEmployeeWorkStatusPage'
    )
)
const DataStatisticResourcePage = lazy(
  () =>
    import('@/pages/admin/dataStatistics/resources/DataStatisticResourcePage')
)
const DataStatisticTaskPage = lazy(
  () => import('@/pages/admin/dataStatistics/tasks/DataStatisticTaskPage')
)
const DataStatisticVisitorPage = lazy(
  () => import('@/pages/admin/dataStatistics/visitors/DataStatisticVisitorPage')
)
const DeliveryInfo = lazy(() => import('@/pages/admin/deliveries/DeliveryInfo'))
const DeliveryPage = lazy(() => import('@/pages/admin/deliveries/DeliveryPage'))
const DeliverySettingPage = lazy(
  () => import('@/pages/admin/deliveries/DeliverySettingPage')
)
const FloorList = lazy(() => import('@/pages/admin/floors/FloorList'))
const LocationSettingPage = lazy(
  () => import('@/pages/admin/locationSettings/LocationSettingPage')
)
const TimeModeFormPage = lazy(
  () =>
    import(
      '@/pages/admin/locationSettings/timeModes/timeModeForm/TimeModeFormPage'
    )
)
const LocationDashboardPage = lazy(
  () => import('@/pages/admin/locations/Dashboard')
)
const LocationsListPage = lazy(
  () => import('@/pages/admin/locations/LocationsListPage')
)
const ResourceReservationPage = lazy(
  () => import('@/pages/admin/resourceReservation/ResourceReservationsPage')
)
const AdminReservationDetailsPage = lazy(
  () =>
    import(
      '@/pages/admin/resourceReservation/components/AdminReservationDetail'
    )
)
const WaitlistDetail = lazy(
  () => import('@/pages/admin/resourceReservation/components/WaitlistDetail')
)
const AdminBookingCalendarPage = lazy(
  () => import('@/pages/admin/resources/AdminBookingCalendarPage')
)
const CalendarSyncRecord = lazy(
  () => import('@/pages/admin/resources/CalendarSyncRecord')
)
const CalendarSyncRecordDetail = lazy(
  () => import('@/pages/admin/resources/CalendarSyncRecordDetail')
)
const ResourceList = lazy(() => import('@/pages/admin/resources/ResourceList'))
const ResourceTypeList = lazy(
  () => import('@/pages/admin/resources/ResourceTypeList')
)
const ReservationBlacklistPage = lazy(
  () => import('@/pages/admin/resources/blacklist/ReservationBlacklistPage')
)
const BlackListDetail = lazy(
  () => import('@/pages/admin/resources/blacklist/details/BlackListDetail')
)
const ViolationDetail = lazy(
  () => import('@/pages/admin/resources/blacklist/details/ViolationDetail')
)
const ResourceEditorPage = lazy(
  () => import('@/pages/admin/resources/mapEditor/ResourceEditorPage')
)
const RuleSettings = lazy(
  () => import('@/pages/admin/ruleSettings/RuleSettings')
)
const BlacklistRuleFormPage = lazy(
  () =>
    import('@/pages/admin/ruleSettings/blacklistRules/BlacklistRuleFormPage')
)
const ReservationFormInfo = lazy(
  () => import('@/pages/admin/ruleSettings/components/ReservationFormInfo')
)
const ReservationUpdateInfo = lazy(
  () => import('@/pages/admin/ruleSettings/components/ReservationUpdateInfo')
)
const ReservationRulesInfo = lazy(
  () => import('@/pages/admin/ruleSettings/components/ReservationRulesInfo')
)
const SafetyRulesInfo = lazy(
  () => import('@/pages/admin/ruleSettings/components/SafetyRulesInfo')
)
const SignRulesInfo = lazy(
  () => import('@/pages/admin/ruleSettings/components/SignRulesInfo')
)
const ServicePage = lazy(() => import('@/pages/admin/services/ServicePage'))
const ServicePageInfo = lazy(
  () => import('@/pages/admin/services/ServiceTypeInfoPage')
)
const SpaceRoleInfo = lazy(
  () => import('@/pages/admin/spaceRoles/SpaceRoleInfo')
)
const SpaceRolePage = lazy(
  () => import('@/pages/admin/spaceRoles/SpaceRolePage')
)
const SpaceSettingPage = lazy(
  () => import('@/pages/admin/spaceSettings/SpaceSettingPage')
)
const TaskCategory = lazy(
  () => import('@/pages/admin/tasks/TaskCategories/TaskCategoryList')
)
const TaskInfo = lazy(() => import('@/pages/admin/tasks/TaskInfo'))
const TasksPage = lazy(() => import('@/pages/admin/tasks/TaskPage'))
const UserInfo = lazy(() => import('@/pages/admin/users/UserInfo'))
const UsersPage = lazy(() => import('@/pages/admin/users/UserPage'))
const VisitorCheckInListPage = lazy(
  () => import('@/pages/admin/visitors/VisitorCheckInListPage')
)
const VisitorInfoPage = lazy(
  () => import('@/pages/admin/visitors/VisitorInfoPage')
)
const VisitorLawDocuments = lazy(
  () => import('@/pages/admin/visitors/VisitorLawDocuments')
)
const VisitorSettingsPage = lazy(
  () => import('@/pages/admin/visitors/VisitorSettingsPage')
)
const VisitorType = lazy(() => import('@/pages/admin/visitors/VisitorType'))

const VisitorTypeInfo = lazy(
  () =>
    import(
      '@/pages/admin/visitors/components/visitorSettingsPage/VisitorTypeInfo'
    )
)
const PadList = lazy(() => import('@/pages/admin/pads/PadList'))
const DeviceRuleSettings = lazy(
  () => import('@/pages/admin/pads/DeviceRuleSettings')
)
const ReservationTabletSettingsInfo = lazy(
  () => import('@/pages/admin/pads/ReservationTabletSettingsInfo')
)
const VisitorTabletSettingsInfo = lazy(
  () => import('@/pages/admin/pads/VisitorTabletSettingsInfo')
)
const WorkStatusListPage = lazy(
  () => import('@/pages/admin/workStatus/WorkStatusListPage')
)
const WorkbenchPage = lazy(
  () => import('@/pages/admin/workbench/WorkbenchPage')
)
const BlankPage = lazy(() => import('@/pages/user/blank/Blank'))
const EditUserFormPage = lazy(
  () => import('@/pages/user/users/EditUserFormPage')
)

export const adminRoutes: RouteObject[] = [
  { path: '', element: <Navigate to="dashboard" replace /> },
  { path: 'dashboard', element: lazyLoad(DashboardPage) },
  { path: 'billing', element: lazyLoad(BillingPage) },
  { path: 'resources', element: lazyLoad(ResourceList) },
  { path: 'resources/types', element: lazyLoad(ResourceTypeList) },
  {
    path: 'resources/reservations',
    element: lazyLoad(ResourceReservationPage),
  },
  {
    path: 'resources/reservations/:id',
    element: lazyLoad(AdminReservationDetailsPage),
  },
  { path: 'apps/all', element: lazyLoad(AllAppsPage) },
  { path: 'apps/installed', element: lazyLoad(InstalledApplications) },
  { path: 'apps/webhooks', element: lazyLoad(WebhooksLayout) },
  { path: 'apps/:id', element: lazyLoad(AppDetails) },
  { path: 'users', element: lazyLoad(UsersPage) },
  { path: 'users/all', element: lazyLoad(UsersPage) },
  { path: 'users/unconfirmed', element: lazyLoad(UsersPage) },
  { path: 'users/dismissed', element: lazyLoad(UsersPage) },
  { path: 'users/active', element: lazyLoad(UsersPage) },
  { path: 'users/new', element: lazyLoad(EditUserFormPage) },
  { path: 'users/edit', element: lazyLoad(EditUserFormPage) },
  { path: 'users/:id', element: lazyLoad(UserInfo) },
  { path: 'roles', element: lazyLoad(SpaceRolePage) },
  { path: 'roles/permissions', element: lazyLoad(SpaceRolePage) },
  { path: 'roles/users', element: lazyLoad(SpaceRolePage) },
  { path: 'roles/:id', element: lazyLoad(SpaceRoleInfo) },
  { path: 'audit-logs', element: lazyLoad(AuditLogsPage) },
  { path: 'space-settings', element: lazyLoad(SpaceSettingPage) },
  { path: 'space-settings/brand', element: lazyLoad(SpaceSettingPage) },
  { path: 'space-settings/basic', element: lazyLoad(SpaceSettingPage) },
  { path: 'space-settings/features', element: lazyLoad(SpaceSettingPage) },
  {
    path: 'space-settings/email-templates',
    element: lazyLoad(SpaceSettingPage),
  },
  { path: 'space-settings/custom-fields', element: lazyLoad(SpaceSettingPage) },
  { path: 'locations-management', element: lazyLoad(LocationsListPage) },
  {
    path: 'data-statistics/resources',
    element: lazyLoad(DataStatisticResourcePage),
  },
  {
    path: 'data-statistics/resources/base-data',
    element: lazyLoad(DataStatisticResourcePage),
  },
  {
    path: 'data-statistics/resources/reservations-number',
    element: lazyLoad(DataStatisticResourcePage),
  },
  {
    path: 'data-statistics/resources/pre-order-heat-map',
    element: lazyLoad(DataStatisticResourcePage),
  },
  {
    path: 'data-statistics/resources/duration',
    element: lazyLoad(DataStatisticResourcePage),
  },
  {
    path: 'data-statistics/resources/resource',
    element: lazyLoad(DataStatisticResourcePage),
  },
  {
    path: 'data-statistics/resources/department',
    element: lazyLoad(DataStatisticResourcePage),
  },
  {
    path: 'data-statistics/resources/floor-or-location',
    element: lazyLoad(DataStatisticResourcePage),
  },
  {
    path: 'data-statistics/resources/resource-category',
    element: lazyLoad(DataStatisticResourcePage),
  },
  {
    path: 'data-statistics/resources/resource-cancellation',
    element: lazyLoad(DataStatisticResourcePage),
  },
  { path: 'data-statistics/tasks', element: lazyLoad(DataStatisticTaskPage) },
  {
    path: 'data-statistics/tasks/base-data',
    element: lazyLoad(DataStatisticTaskPage),
  },
  {
    path: 'data-statistics/tasks/task-number',
    element: lazyLoad(DataStatisticTaskPage),
  },
  {
    path: 'data-statistics/tasks/responsible-order',
    element: lazyLoad(DataStatisticTaskPage),
  },
  {
    path: 'data-statistics/tasks/task-category',
    element: lazyLoad(DataStatisticTaskPage),
  },
  {
    path: 'data-statistics/tasks/priority',
    element: lazyLoad(DataStatisticTaskPage),
  },
  {
    path: 'data-statistics/deliveries',
    element: lazyLoad(DataStatisticDeliveryPage),
  },
  {
    path: 'data-statistics/deliveries/base-data',
    element: lazyLoad(DataStatisticDeliveryPage),
  },
  {
    path: 'data-statistics/deliveries/delivery-number',
    element: lazyLoad(DataStatisticDeliveryPage),
  },
  {
    path: 'data-statistics/deliveries/responsible-order',
    element: lazyLoad(DataStatisticDeliveryPage),
  },
  {
    path: 'data-statistics/deliveries/delivery-category',
    element: lazyLoad(DataStatisticDeliveryPage),
  },
  {
    path: 'data-statistics/deliveries/delivery-status',
    element: lazyLoad(DataStatisticDeliveryPage),
  },
  {
    path: 'data-statistics/visitors',
    element: lazyLoad(DataStatisticVisitorPage),
  },
  {
    path: 'data-statistics/visitors/base-data',
    element: lazyLoad(DataStatisticVisitorPage),
  },
  {
    path: 'data-statistics/visitors/visitor-check-in',
    element: lazyLoad(DataStatisticVisitorPage),
  },
  {
    path: 'data-statistics/visitors/employees-visited',
    element: lazyLoad(DataStatisticVisitorPage),
  },
  {
    path: 'data-statistics/visitors/check-in-density',
    element: lazyLoad(DataStatisticVisitorPage),
  },
  {
    path: 'data-statistics/visitors/visitor-source',
    element: lazyLoad(DataStatisticVisitorPage),
  },
  {
    path: 'data-statistics/visitors/visitor-category',
    element: lazyLoad(DataStatisticVisitorPage),
  },
  {
    path: 'data-statistics/employee-work-status',
    element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
  },
  {
    path: 'data-statistics/employee-work-status/base-data',
    element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
  },
  {
    path: 'data-statistics/employee-work-status/ratio',
    element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
  },
  {
    path: 'data-statistics/employee-work-status/location-ratio',
    element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
  },
  {
    path: 'data-statistics/employee-work-status/ratio-week',
    element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
  },
  {
    path: 'data-statistics/employee-work-status/work-company-number',
    element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
  },
  {
    path: 'data-statistics/employee-work-status/work-company-number-week',
    element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
  },
  {
    path: 'data-statistics/employee-work-status/department',
    element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
  },
  { path: 'visitors/types', element: lazyLoad(VisitorType) },
  { path: 'visitors/types/add-type', element: lazyLoad(VisitorTypeInfo) },
  { path: 'visitors/types/:id', element: lazyLoad(VisitorTypeInfo) },
  { path: 'visitors/documents', element: lazyLoad(VisitorLawDocuments) },
  { path: 'workbench', element: lazyLoad(WorkbenchPage) },
  { path: 'blank', element: lazyLoad(BlankPage) },
  {
    path: 'locations',
    element: <Outlet />,
    children: [
      {
        path: ':location_id',
        element: <LocationLayout />,
        children: [
          { path: '', element: <Navigate to="dashboard" replace /> },
          { path: 'dashboard', element: lazyLoad(LocationDashboardPage) },
          { path: 'areas', element: lazyLoad(AreasListPage) },
          { path: 'resources/list', element: lazyLoad(ResourceList) },
          { path: 'resources/types', element: lazyLoad(ResourceTypeList) },
          {
            path: 'resources/calendar',
            element: lazyLoad(AdminBookingCalendarPage),
          },
          {
            path: 'resources/blacklist',
            element: lazyLoad(ReservationBlacklistPage),
          },
          {
            path: 'resources/blacklist/violationRecord',
            element: lazyLoad(ReservationBlacklistPage),
          },
          {
            path: 'resources/blacklist/blacklistRecord',
            element: lazyLoad(ReservationBlacklistPage),
          },
          {
            path: 'resources/blacklist/operationRecord',
            element: lazyLoad(ReservationBlacklistPage),
          },
          {
            path: 'map-editor/:floor_id',
            element: lazyLoad(ResourceEditorPage),
          },
          {
            path: 'resources/calendar-sync',
            element: lazyLoad(CalendarSyncRecord),
          },
          {
            path: 'resources/calendar-sync/:id',
            element: lazyLoad(CalendarSyncRecordDetail),
          },
          { path: 'resources/settings', element: lazyLoad(RuleSettings) },
          {
            path: 'resources/settings/time-mode',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'resources/settings/reservation-rules',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'resources/settings/sign-rules',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'resources/settings/safety-rules',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'resources/settings/reservation-form-rules',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'resources/settings/reservation-update-rules',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'resources/settings/service-rules',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'resources/settings/blacklist-rules',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'resources/settings/reservation-tablet-rules',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'resources/settings/asset-category',
            element: lazyLoad(RuleSettings),
          },
          {
            path: 'reservation-rules-info',
            element: lazyLoad(ReservationRulesInfo),
          },
          {
            path: 'reservation-rules-info/:id',
            element: lazyLoad(ReservationRulesInfo),
          },
          { path: 'sign-rules-info', element: lazyLoad(SignRulesInfo) },
          { path: 'sign-rules-info/:id', element: lazyLoad(SignRulesInfo) },
          { path: 'safety-rules-info', element: lazyLoad(SafetyRulesInfo) },
          { path: 'safety-rules-info/:id', element: lazyLoad(SafetyRulesInfo) },
          {
            path: 'reservation-form-info',
            element: lazyLoad(ReservationFormInfo),
          },
          {
            path: 'reservation-update-info/add',
            element: lazyLoad(ReservationUpdateInfo),
          },
          {
            path: 'reservation-update-info/edit/:id',
            element: lazyLoad(ReservationUpdateInfo),
          },
          {
            path: 'blacklist-rules/add',
            element: lazyLoad(BlacklistRuleFormPage),
          },
          {
            path: 'blacklist-rules/edit/:id',
            element: lazyLoad(BlacklistRuleFormPage),
          },
          {
            path: 'reservation-form-info/:id',
            element: lazyLoad(ReservationFormInfo),
          },
          {
            path: 'resources/reservations',
            element: lazyLoad(ResourceReservationPage),
          },
          {
            path: 'resources/reservations/types/:id',
            element: lazyLoad(ResourceReservationPage),
          },
          {
            path: 'resources/reservations/records',
            element: lazyLoad(ResourceReservationPage),
          },
          {
            path: 'resources/reservations/waitlistRecords',
            element: lazyLoad(ResourceReservationPage),
          },
          {
            path: 'resources/reservations/:id',
            element: lazyLoad(AdminReservationDetailsPage),
          },
          { path: 'resources/waitlist/:id', element: lazyLoad(WaitlistDetail) },
          {
            path: 'resources/violations/:id',
            element: lazyLoad(ViolationDetail),
          },
          {
            path: 'resources/blacklist/:id',
            element: lazyLoad(BlackListDetail),
          },
          { path: 'approvals', element: lazyLoad(ApprovalList) },
          { path: 'approvals/all', element: lazyLoad(ApprovalList) },
          { path: 'approvals/approving', element: lazyLoad(ApprovalList) },
          { path: 'approvals/approved', element: lazyLoad(ApprovalList) },
          { path: 'approvals/rejected', element: lazyLoad(ApprovalList) },
          { path: 'approvals/:id', element: lazyLoad(ApprovalDetail) },
          { path: 'pads', element: lazyLoad(PadList) },
          {
            path: 'pads/settings/visitor',
            element: lazyLoad(DeviceRuleSettings),
          },
          {
            path: 'pads/settings/reservation',
            element: lazyLoad(DeviceRuleSettings),
          },
          {
            path: 'pads/settings/visitor-tablet-rules-info/edit',
            element: lazyLoad(VisitorTabletSettingsInfo),
          },
          {
            path: 'reservation-tablet-rules-info',
            element: lazyLoad(ReservationTabletSettingsInfo),
          },
          {
            path: 'reservation-tablet-rules-info/:id',
            element: lazyLoad(ReservationTabletSettingsInfo),
          },
          { path: 'work-status', element: lazyLoad(WorkStatusListPage) },
          { path: 'work-status/all', element: lazyLoad(WorkStatusListPage) },
          {
            path: 'work-status/work-office',
            element: lazyLoad(WorkStatusListPage),
          },
          { path: 'work-status/rest', element: lazyLoad(WorkStatusListPage) },
          {
            path: 'work-status/work-home',
            element: lazyLoad(WorkStatusListPage),
          },
          {
            path: 'work-status/unknown',
            element: lazyLoad(WorkStatusListPage),
          },
          { path: 'floors', element: lazyLoad(FloorList) },
          { path: 'users', element: lazyLoad(UsersPage) },
          { path: 'users/all', element: lazyLoad(UsersPage) },
          { path: 'users/unconfirmed', element: lazyLoad(UsersPage) },
          { path: 'users/dismissed', element: lazyLoad(UsersPage) },
          { path: 'users/active', element: lazyLoad(UsersPage) },
          { path: 'users/:id', element: lazyLoad(UserInfo) },
          { path: 'users/new', element: lazyLoad(EditUserFormPage) },
          { path: 'users/edit', element: lazyLoad(EditUserFormPage) },
          { path: 'tasks', element: lazyLoad(TasksPage) },
          { path: 'tasks/all', element: lazyLoad(TasksPage) },
          { path: 'tasks/pending', element: lazyLoad(TasksPage) },
          { path: 'tasks/completed', element: lazyLoad(TasksPage) },
          { path: 'tasks/rejected', element: lazyLoad(TasksPage) },
          { path: 'tasks/received', element: lazyLoad(TasksPage) },
          { path: 'tasks/:id', element: lazyLoad(TaskInfo) },
          { path: 'tasks/categories', element: lazyLoad(TaskCategory) },
          { path: 'deliveries', element: lazyLoad(DeliveryPage) },
          { path: 'deliveries/all', element: lazyLoad(DeliveryPage) },
          { path: 'deliveries/pending', element: lazyLoad(DeliveryPage) },
          { path: 'deliveries/pickedUp', element: lazyLoad(DeliveryPage) },
          { path: 'deliveries/rejected', element: lazyLoad(DeliveryPage) },
          { path: 'deliveries/expired', element: lazyLoad(DeliveryPage) },
          { path: 'deliveries/:id', element: lazyLoad(DeliveryInfo) },
          {
            path: 'deliveries/settings',
            element: lazyLoad(DeliverySettingPage),
          },
          {
            path: 'deliveries/settings/types',
            element: lazyLoad(DeliverySettingPage),
          },
          {
            path: 'deliveries/settings/areas',
            element: lazyLoad(DeliverySettingPage),
          },
          {
            path: 'deliveries/settings/more',
            element: lazyLoad(DeliverySettingPage),
          },
          { path: 'roles', element: lazyLoad(SpaceRolePage) },
          { path: 'roles/permissions', element: lazyLoad(SpaceRolePage) },
          { path: 'roles/users', element: lazyLoad(SpaceRolePage) },
          { path: 'roles/:id', element: lazyLoad(SpaceRoleInfo) },
          { path: 'announcements', element: lazyLoad(AnnouncementList) },
          { path: 'audit-logs', element: lazyLoad(AuditLogsPage) },
          {
            path: 'locations-settings',
            element: lazyLoad(LocationSettingPage),
          },
          {
            path: 'locations-settings/basic',
            element: lazyLoad(LocationSettingPage),
          },
          {
            path: 'locations-settings/general',
            element: lazyLoad(LocationSettingPage),
          },
          {
            path: 'locations-settings/security',
            element: lazyLoad(LocationSettingPage),
          },
          {
            path: 'locations-settings/time-modes',
            element: lazyLoad(LocationSettingPage),
          },
          {
            path: 'locations-settings/features',
            element: lazyLoad(LocationSettingPage),
          },
          {
            path: 'locations-settings/email-templates',
            element: lazyLoad(LocationSettingPage),
          },
          {
            path: 'locations-settings/app-links',
            element: lazyLoad(LocationSettingPage),
          },
          {
            path: 'locations-settings/notifications',
            element: lazyLoad(LocationSettingPage),
          },
          { path: 'time-modes/add', element: lazyLoad(TimeModeFormPage) },
          { path: 'time-modes/edit/:id', element: lazyLoad(TimeModeFormPage) },
          { path: 'services', element: lazyLoad(ServicePage) },
          { path: 'services/:id', element: lazyLoad(ServicePageInfo) },
          { path: 'blacklist/add', element: lazyLoad(ServicePage) },
          { path: 'blacklist/edit/:id', element: lazyLoad(ServicePage) },
          {
            path: 'data-statistics/resources',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/resources/base-data',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/resources/reservations-number',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/resources/pre-order-heat-map',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/resources/duration',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/resources/resource',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/resources/department',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/resources/floor-or-location',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/resources/resource-category',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/resources/resource-cancellation',
            element: lazyLoad(DataStatisticResourcePage),
          },
          {
            path: 'data-statistics/tasks',
            element: lazyLoad(DataStatisticTaskPage),
          },
          {
            path: 'data-statistics/tasks/base-data',
            element: lazyLoad(DataStatisticTaskPage),
          },
          {
            path: 'data-statistics/tasks/task-number',
            element: lazyLoad(DataStatisticTaskPage),
          },
          {
            path: 'data-statistics/tasks/responsible-order',
            element: lazyLoad(DataStatisticTaskPage),
          },
          {
            path: 'data-statistics/tasks/task-category',
            element: lazyLoad(DataStatisticTaskPage),
          },
          {
            path: 'data-statistics/tasks/priority',
            element: lazyLoad(DataStatisticTaskPage),
          },
          {
            path: 'data-statistics/deliveries',
            element: lazyLoad(DataStatisticDeliveryPage),
          },
          {
            path: 'data-statistics/deliveries/base-data',
            element: lazyLoad(DataStatisticDeliveryPage),
          },
          {
            path: 'data-statistics/deliveries/delivery-number',
            element: lazyLoad(DataStatisticDeliveryPage),
          },
          {
            path: 'data-statistics/deliveries/responsible-order',
            element: lazyLoad(DataStatisticDeliveryPage),
          },
          {
            path: 'data-statistics/deliveries/delivery-category',
            element: lazyLoad(DataStatisticDeliveryPage),
          },
          {
            path: 'data-statistics/deliveries/delivery-status',
            element: lazyLoad(DataStatisticDeliveryPage),
          },
          {
            path: 'data-statistics/visitors',
            element: lazyLoad(DataStatisticVisitorPage),
          },
          {
            path: 'data-statistics/visitors/base-data',
            element: lazyLoad(DataStatisticVisitorPage),
          },
          {
            path: 'data-statistics/visitors/visitor-check-in',
            element: lazyLoad(DataStatisticVisitorPage),
          },
          {
            path: 'data-statistics/visitors/employees-visited',
            element: lazyLoad(DataStatisticVisitorPage),
          },
          {
            path: 'data-statistics/visitors/check-in-density',
            element: lazyLoad(DataStatisticVisitorPage),
          },
          {
            path: 'data-statistics/visitors/visitor-source',
            element: lazyLoad(DataStatisticVisitorPage),
          },
          {
            path: 'data-statistics/visitors/visitor-category',
            element: lazyLoad(DataStatisticVisitorPage),
          },
          {
            path: 'data-statistics/employee-work-status',
            element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
          },
          {
            path: 'data-statistics/employee-work-status/base-data',
            element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
          },
          {
            path: 'data-statistics/employee-work-status/ratio',
            element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
          },
          {
            path: 'data-statistics/employee-work-status/ratio-week',
            element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
          },
          {
            path: 'data-statistics/employee-work-status/work-company-number',
            element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
          },
          {
            path: 'data-statistics/employee-work-status/work-company-number-week',
            element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
          },
          {
            path: 'data-statistics/employee-work-status/department',
            element: lazyLoad(DataStatisticEmployeeWorkStatusPage),
          },
          {
            path: 'visitors',
            element: <VisitorLayout />,
            children: [
              { path: 'today', element: lazyLoad(VisitorCheckInListPage) },
              {
                path: 'today/sign-in',
                element: lazyLoad(VisitorCheckInListPage),
              },
              {
                path: 'today/signed-out',
                element: lazyLoad(VisitorCheckInListPage),
              },
              {
                path: 'today/in-building',
                element: lazyLoad(VisitorCheckInListPage),
              },
              { path: ':id', element: lazyLoad(VisitorInfoPage) },
              { path: 'types/:id', element: lazyLoad(VisitorTypeInfo) },
              { path: 'settings', element: lazyLoad(VisitorSettingsPage) },
              {
                path: 'settings/categories',
                element: lazyLoad(VisitorSettingsPage),
              },
              {
                path: 'settings/law',
                element: lazyLoad(VisitorSettingsPage),
              },
              {
                path: 'settings/sign-in',
                element: lazyLoad(VisitorSettingsPage),
              },
              {
                path: 'settings/sign-out',
                element: lazyLoad(VisitorSettingsPage),
              },
              {
                path: 'settings/guidance',
                element: lazyLoad(VisitorSettingsPage),
              },
              {
                path: 'settings/tablet',
                element: lazyLoad(VisitorSettingsPage),
              },
              {
                path: 'settings/notification',
                element: lazyLoad(VisitorSettingsPage),
              },
              {
                path: 'settings/approval',
                element: lazyLoad(VisitorSettingsPage),
              },
              {
                path: 'settings/security',
                element: lazyLoad(VisitorSettingsPage),
              },
              {
                path: 'settings/access',
                element: lazyLoad(VisitorSettingsPage),
              },
            ],
          },
          { path: 'blank', element: lazyLoad(BlankPage) },
        ],
      },
    ],
  },
]
