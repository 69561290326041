// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { VisitorVisitorApprovalPassedMutationRequest, VisitorVisitorApprovalPassedMutationResponse, VisitorVisitorApprovalPassedQueryParams, VisitorVisitorApprovalPassed401 } from "../models/VisitorVisitorApprovalPassed";
import type { UseMutationOptions } from "@tanstack/react-query";

 type VisitorVisitorApprovalPassedClient = typeof client<VisitorVisitorApprovalPassedMutationResponse, VisitorVisitorApprovalPassed401, VisitorVisitorApprovalPassedMutationRequest>;
type VisitorVisitorApprovalPassed = {
    data: VisitorVisitorApprovalPassedMutationResponse;
    error: VisitorVisitorApprovalPassed401;
    request: VisitorVisitorApprovalPassedMutationRequest;
    pathParams: never;
    queryParams: VisitorVisitorApprovalPassedQueryParams;
    headerParams: never;
    response: VisitorVisitorApprovalPassedMutationResponse;
    client: {
        parameters: Partial<Parameters<VisitorVisitorApprovalPassedClient>[0]>;
        return: Awaited<ReturnType<VisitorVisitorApprovalPassedClient>>;
    };
};
/**
 * @summary 访客审批通过
 * @link /api/v5/visitors/visitor-approval-passed
 */
export function useVisitorVisitorApprovalPassed(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<VisitorVisitorApprovalPassed["response"], VisitorVisitorApprovalPassed["error"], {
        params?: VisitorVisitorApprovalPassed["queryParams"];
        data?: VisitorVisitorApprovalPassed["request"];
    }>;
    client?: VisitorVisitorApprovalPassed["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ params, data }) => {
            const res = await client<VisitorVisitorApprovalPassed["data"], VisitorVisitorApprovalPassed["error"], VisitorVisitorApprovalPassed["request"]>({
                method: "post",
                url: `/api/v5/visitors/visitor-approval-passed`,
                params,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "visitors", "visitor-approval-passed"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}