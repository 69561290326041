import AuthenticatedLayout from '@/components/layouts/AuthenticatedLayout'
import UserLayout from '@/components/layouts/UserLayout'
import { adminRoutes } from '@/pages/admin/routes'
import VisitorSecurityListPage from '@/pages/admin/visitors/VisitorSecurityListPage'
import { authRoutes } from '@/pages/auth/routes'
import { userRoutes } from '@/pages/user/routes'
import AdminRoute from '@/routes/AdminRoute'
import ProtectedRoute from '@/routes/ProtectedRoute'
import RedirectToDashboard from '@/routes/RedirectToDashboard'
import Root from '@/routes/root'
import React, { lazy, Suspense } from 'react'
import { RouteObject } from 'react-router-dom'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <RedirectToDashboard />,
      },
      ...authRoutes,
      {
        element: <AuthenticatedLayout />,
        children: [
          {
            path: 'admin',
            element: (
              <AdminRoute
                element={
                  <Suspense fallback={null}>
                    {React.createElement(
                      lazy(() => import('@/components/layouts/AdminLayout'))
                    )}
                  </Suspense>
                }
              />
            ),
            children: adminRoutes,
          },
          {
            path: 'user',
            element: <ProtectedRoute element={<UserLayout />} />,
            children: userRoutes,
          },
        ],
      },
      {
        path: 'security/visitors',
        element: <VisitorSecurityListPage />,
      },
    ],
  },
]

export default routes
