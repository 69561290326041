import { useAtom } from 'jotai'
import { useMemo } from 'react'

import { LocationEntity, useLocationGetAllLocations } from '@/api'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { currentUserDataAtom } from '@/store'

/**
 * 使用用户的分店信息，如果没有则使用当前分店，或默认的分店
 * @returns
 */
const useUserLocationOrDefault = () => {
  const [currentUser] = useAtom(currentUserDataAtom)
  const currentLocationId = useCurrentLocationId()
  const { data: locations } = useLocationGetAllLocations({})

  const location = useMemo(() => {
    const locationId = currentLocationId || currentUser?.location_id
    let location
    if (locationId) {
      location = locations?.find(
        (location: LocationEntity) => location.id === locationId
      )
    }
    location ||= locations?.[0]
    return location
  }, [locations, currentUser?.location_id, currentLocationId])

  return location
}

export default useUserLocationOrDefault
