import { Space } from 'antd'

import { SimpleUserBookEntity, SimpleUserEntity } from '@/api'
import { KBFlex, KBSpace, KBText, KBTypography } from '@/components/atoms'
import { KBAvatar } from '@/components/atoms/KBAvatar'
import { KBUserPopover } from '@/components/molecules/KBUserPopover'
import { getUserAvatar } from '@/utils/avatarUrl'
import { cn } from '@/utils/tailwind.helper'
const { Text } = KBTypography
export type AvatarSize = number | 'small' | 'default' | 'large'
export interface KBUserProps {
  user?: SimpleUserEntity | SimpleUserBookEntity
  size?: AvatarSize
  showName?: boolean
  showDepartment?: boolean
  /**
   * 是否显示 tooltip, 默认为 true, 显示则鼠标 hover 时显示用户名
   */
  showTooltip?: boolean

  /**
   * 子元素
   */
  children?: React.ReactNode

  /**
   * tooltip 内容
   */
  toolTipContent?: React.ReactNode
  /**
   * 头像和名字的距离
   */
  gapNumber?: number
  /**
   * 自定义样式
   */
  className?: string
  /**
   * 自定义用户名样式
   */
  textClassName?: string
}

export function KBUser({
  user,
  size = 32,
  showTooltip = true,
  showDepartment = false,
  showName = true,
  toolTipContent,
  gapNumber = 8,
  className,
  textClassName,
  children,
}: KBUserProps) {
  if (!user) {
    return <KBText>-</KBText>
  }
  const element = (
    <KBSpace size={gapNumber}>
      <KBAvatar size={size} src={getUserAvatar(user, size)} />
      <KBFlex vertical>
        <KBFlex gap={4} align="center">
          {showName && (
            <Text
              className={cn('tw-font-medium tw-leading-none', textClassName)}
            >
              {user?.name}
            </Text>
          )}
          {children}
        </KBFlex>
        {showDepartment && 'department' in user && (
          <Text type="secondary" className="tw-font-normal">
            {user.department?.name}
          </Text>
        )}
      </KBFlex>
    </KBSpace>
  )

  return (
    <Space className={className}>
      {showTooltip ? (
        <KBUserPopover user={user} toolTipContent={toolTipContent}>
          {element}
        </KBUserPopover>
      ) : (
        element
      )}
    </Space>
  )
}
