import { ReservationShowEntity, ResourceEntity } from '@/api'
import { KBForm, KBIcon, KBInput } from '@/components/atoms'
import { GroupEditType } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'

interface SubjectInputProps {
  resourcesList: ResourceEntity[]
  reservationInfo?: ReservationShowEntity
  groupEditType?: GroupEditType
  errorsMessages?: string
}
/**
 * 会议主题组件
 */
const SubjectInput: React.FC<SubjectInputProps> = (props) => {
  const { resourcesList, reservationInfo, groupEditType, errorsMessages } =
    props

  /**
  const validateSubject = (subject: string) => {
    if (subject?.length) {
      const cannotInputSubjects = resourcesList.filter(
        (i) =>
          !(i.reservation_form_rule?.rules as TReservationFormRule)?.subject
      )
      if (cannotInputSubjects?.length) {
        return Promise.reject(
          new Error(
            `${cannotInputSubjects.map((i) => i.name).join('、')}${tr('[intl]不允许输入主题')}`
          )
        )
      }
      return Promise.resolve()
    } else {
      return Promise.resolve()
    }
  }
  */

  const showSubject = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      return rules?.subject
    })
  }, [resourcesList])

  const disabled = useMemo(() => {
    if (!reservationInfo) return false

    const { is_group } = reservationInfo

    return groupEditType === 'current'
  }, [reservationInfo])

  return showSubject ? (
    <KBForm.Item
      name="subject"
      label={<KBIcon name="pen-line" size={24} />}
      dependencies={['resourceIds']}
      // rules={[
      //   {
      //     validator: (_, subject) => validateSubject(subject),
      //   },
      // ]}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <KBInput
        placeholder={tr('reservation.subjectPlaceholder')}
        disabled={disabled}
      />
    </KBForm.Item>
  ) : null
}

export default SubjectInput
