import { Form, FormInstance } from 'antd'
import React from 'react'

import {
  KBButton,
  KBCheckbox,
  KBDivider,
  KBFlex,
  KBForm,
  KBInput,
} from '@/components/atoms'
import { kbModal } from '@/components/atoms/KBMessageGlobal'
import { BACKEND_URL, PRIVACY_URL, TERMS_URL } from '@/utils/constants'
import { trHTML } from '@/utils/localization/tr'
import WoxIconGoogle from '~icons/wox-icons/google'
import WoxIconMicrosoft from '~icons/wox-icons/microsoft'

interface emailFormProp {
  form: FormInstance
  onEnterEmail: () => void
}

const EmailForm: React.FC<emailFormProp> = ({ form, onEnterEmail }) => {
  const agreeTerms = Form.useWatch('agree_terms', form)
  const validateAgreeTerms = () => {
    if (!agreeTerms) {
      kbModal.error({
        title: tr('login.notAgreeToTerms'),
        content: tr('login.agreeToTermsDescription'),
      })
      return false
    }
    return true
  }
  const microsoftLogin = () => {
    // openAuthWindow(`${BACKEND_URL}/api/v5/auth/azure`, `login`, (result) => {
    //   if (result) {
    //     // callback
    //   }
    // })
    if (!validateAgreeTerms()) {
      return
    }
    window.location.href = `${BACKEND_URL}/api/v5/auth/azure?redirect_uri=${window.location.origin}`
  }

  const googleLogin = () => {
    if (!validateAgreeTerms()) {
      return
    }
    window.location.href = `${BACKEND_URL}/api/v5/auth/google?redirect_uri=${window.location.origin}`
  }

  const submitForm = () => {
    if (!validateAgreeTerms()) {
      return
    }
    if (!form.getFieldValue('email')) {
      kbModal.error({
        title: tr('login.error'),
        content: tr('login.enterEmail'),
      })
      return
    }
    if (!agreeTerms) {
      kbModal.error({
        title: tr('login.notAgreeToTerms'),
        content: tr('login.agreeToTermsDescription'),
      })
      return
    }
    onEnterEmail()
  }

  return (
    <KBFlex vertical className="tw-w-full tw-px-4 tw-pt-4">
      <KBForm.Item
        name="email"
        label={tr('locations.quickReserve.mailbox')}
        required
      >
        <KBInput size="large" />
      </KBForm.Item>
      <KBForm.Item>
        <KBButton
          type="primary"
          size="large"
          onClick={submitForm}
          className="tw-w-full"
        >
          {tr('step.next')}
        </KBButton>
      </KBForm.Item>
      <KBForm.Item
        name="agree_terms"
        valuePropName="checked"
        className="tw-mb-0 tw-h-5 tw-text-left"
      >
        <KBCheckbox className="tw-mr-2" data-testid="agree-to-terms">
          {trHTML('login.agreeToTerms', {
            terms_url: TERMS_URL,
            privacy_url: PRIVACY_URL,
          })}
        </KBCheckbox>
      </KBForm.Item>
      <KBDivider className="tw-my-8 " />
      <KBFlex vertical gap={12} className="tw-flex">
        <KBButton
          className="tw-w-full"
          onClick={microsoftLogin}
          size="large"
          icon={<WoxIconMicrosoft />}
        >
          {tr('microsoft.login.text')}
        </KBButton>

        <KBButton
          className="tw-w-full"
          onClick={googleLogin}
          size="large"
          icon={<WoxIconGoogle />}
        >
          {tr('google.login.text')}
        </KBButton>
      </KBFlex>
    </KBFlex>
  )
}

export default EmailForm
