import { Checkbox, Table } from 'antd'
import { RowSelectMethod } from 'antd/es/table/interface'
import { ReactNode } from 'react'

import { Pagination } from '@/api'
import { KBButton } from '@/components/atoms/KBButton'
import { KBFlex } from '@/components/atoms/KBFlex'
import {
  KBSkeletonTable,
  KBSkeletonTableProps,
} from '@/components/atoms/KBSkeletonTable'
import { KBSpace } from '@/components/atoms/KBSpace'
import { KBSpin } from '@/components/atoms/KBSpin'
import { KBText } from '@/components/atoms/KBText'
import { KBEmpty, KBEmptySize } from '@/components/organisms/KBEmpty'

export enum LoadingType {
  Skeleton = 'skeleton',
  Spin = 'spin',
  Progress = 'progress',
}

export interface KBTableProps extends KBSkeletonTableProps {
  loadingType?: LoadingType
  queryComponent?: ReactNode //查询表单
  meta?: Pagination // pagination
  enableRowSelection?: boolean //是否禁用批量选择
  batchOps?: (
    currentSelectedRowKeys: React.Key[],
    isSelectAll?: boolean
  ) => ReactNode //操作的Buttons
  showSelectAll?: boolean
  showSelectBar?: boolean
  emptySize?: KBEmptySize
}

export function KBTable(props: KBTableProps) {
  const {
    queryComponent,
    loadingType,
    rowKey = 'id',
    rowSelection,
    enableRowSelection,
    meta,
    dataSource,
    loading,
    showSelectAll = true,
    showSelectBar = true,
    batchOps,
    emptySize,
    ...tableProps
  } = props
  const myLoadingType = loadingType || LoadingType.Spin

  const [currentSelectedRowKeys, setCurrentSelectedKeys] = useState<
    React.Key[]
  >([])

  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)

  const isHasSelectedRowKeys = useMemo(
    () => currentSelectedRowKeys.length > 0,
    [currentSelectedRowKeys]
  )

  function onRowSelectionChange(
    selectedRowKeys: React.Key[],
    selectedRows: typeof dataSource,
    info: { type: RowSelectMethod }
  ) {
    setCurrentSelectedKeys(selectedRowKeys)

    rowSelection?.onChange &&
      rowSelection?.onChange(selectedRowKeys, selectedRows, info)
  }
  useEffect(() => {
    if (rowSelection?.defaultSelectedRowKeys) {
      setCurrentSelectedKeys(rowSelection.defaultSelectedRowKeys || [])
    }
    if (rowSelection?.selectedRowKeys) {
      setCurrentSelectedKeys(rowSelection.selectedRowKeys || [])
    }
  }, [rowSelection])

  return (
    <div>
      {isHasSelectedRowKeys && showSelectBar ? (
        <KBFlex justify="space-between" className="tw-mb-5" align="center">
          <KBSpace size={'middle'} className=" tw-ml-2 tw-h-[36px] ">
            <Checkbox
              indeterminate={isHasSelectedRowKeys && !isSelectAll}
              checked={isSelectAll}
            />
            <KBText>
              {tr('table.selectedItems', {
                length: isSelectAll
                  ? meta?.count
                  : currentSelectedRowKeys.length,
              })}
            </KBText>
            {/* {meta && meta.count > meta.limit && ( */}
            {showSelectAll && (
              <KBButton
                type="text"
                className="tw-text-[var(--wox-color-primary)]"
                onClick={() => {
                  setIsSelectAll(!isSelectAll)
                  // setCurrentSelectedKeys(
                  //   dataSource.map((data) => data[rowKey as string])
                  // )
                }}
              >
                {isSelectAll
                  ? tr('common.cancelSelectAll')
                  : tr('common.selectAllItems', { count: meta?.count })}
              </KBButton>
            )}
            {/* )} */}

            {batchOps && batchOps(currentSelectedRowKeys, isSelectAll)}
          </KBSpace>
          <KBButton
            type="link"
            onClick={() => {
              setCurrentSelectedKeys([])
            }}
          >
            {tr('common.cancel')}
          </KBButton>
        </KBFlex>
      ) : (
        queryComponent
      )}
      {myLoadingType === LoadingType.Skeleton ? (
        <KBSkeletonTable
          rowSelection={
            !enableRowSelection
              ? undefined
              : {
                  ...rowSelection,
                  selectedRowKeys: currentSelectedRowKeys,
                  onChange: onRowSelectionChange,
                }
          }
          dataSource={dataSource}
          locale={{
            emptyText: !loading ? <KBEmpty size={emptySize} /> : <KBSpin />,
          }}
          {...({ rowKey, ...tableProps } as Omit<
            KBSkeletonTableProps,
            'dataSource'
          >)}
          loading={!!loading}
          pagination={false}
        />
      ) : (
        <Table
          rowSelection={
            !enableRowSelection
              ? undefined
              : {
                  ...rowSelection,
                  selectedRowKeys: currentSelectedRowKeys,
                  onChange: onRowSelectionChange,
                }
          }
          dataSource={dataSource}
          locale={{
            emptyText: !loading ? (
              <KBEmpty size={emptySize} />
            ) : (
              <div className="tw-h-[250px]"></div>
            ),
          }}
          {...{ rowKey, ...tableProps }}
          loading={{
            spinning: !!loading,
            indicator: (
              <svg
                className="tw-text-green tw-ml-1 tw-mr-3 tw-h-5 tw-w-5 tw-animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="tw-opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="tw-opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ),
          }}
          pagination={false}
        ></Table>
      )}
    </div>
  )
}
