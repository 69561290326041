import NiceModal, { useModal } from '@ebay/nice-modal-react'

import {
  KBButton,
  KBFlex,
  KBIcon,
  KBModal,
  KBTypography,
} from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { Input } from 'antd'
import copyToClipboard from 'copy-to-clipboard'

function ShareModal() {
  const modal = useModal()
  const params = useParams()
  // FIXME:前段暂时写死，需调用后端接口
  const [copyText, setCopyText] = useState(
    'http://h5.ent-v3-dev.kuban.io/pages/subPages/visitor/type/sdqded'
  )

  return (
    <KBModal modal={modal} title={tr('common.share')} footer={null} width={512}>
      <KBTypography.Text className="tw-mb-1 tw-block tw-text-base tw-font-bold">
        {tr('common.copyReservationLink')}
      </KBTypography.Text>
      <KBTypography.Text type="secondary" className="tw-mb-6 tw-block ">
        {tr('common.copyReservationLink')}
      </KBTypography.Text>
      <KBFlex gap={0}>
        <Input
          readOnly
          size="large"
          value={copyText}
          className="tw-rounded-[6px_0px_0px_6px]"
        />
        <KBButton
          className="-tw-ml-[1px]  tw-rounded-[0px_6px_6px_0px] tw-p-2"
          icon={<KBIcon name="copy" />}
          onClick={() => {
            copyToClipboard(copyText)
            kbMessage.success(tr('common.copySuccess'))
          }}
        />
      </KBFlex>
    </KBModal>
  )
}

export default NiceModal.create(ShareModal)
