import { MenuProps } from 'antd'

import {
  RecurringEventDto,
  RecurringEventDtoBymonth,
  RecurringEventDtoByweekday,
  RecurringEventDtoFreq,
} from '@/api'
import { KBText } from '@/components/atoms'
import { RecurrenceInfo } from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import dayjs, { formatDate, Frequency, nameOfDay } from '@wox/dayjs'
import { rrulestr } from 'rrule'

export enum RecurrenceInterval {
  day = Frequency.DAILY,
  week = Frequency.WEEKLY,
  month = Frequency.MONTHLY,
  year = Frequency.YEARLY,
  noRecurrence = -1,
}

export const getRecurrenceIntervalDescription = (
  interval?: RecurrenceInterval
): string => {
  switch (interval) {
    case RecurrenceInterval.noRecurrence:
      return tr('recurrence.noRecurrence')
    case RecurrenceInterval.day:
      return tr('recurrence.day')
    case RecurrenceInterval.week:
      return tr('recurrence.week')
    case RecurrenceInterval.month:
      return tr('recurrence.month')
    case RecurrenceInterval.year:
      return tr('recurrence.year')
    default:
      return tr('recurrence.noRecurrence')
  }
}

export const getRecurrenceIntervalOptions = (): MenuProps['items'] => {
  return [
    RecurrenceInterval.noRecurrence,
    RecurrenceInterval.day,
    RecurrenceInterval.week,
    RecurrenceInterval.month,
    // 暂时去掉按年重复
    // RecurrenceInterval.year,
  ].map((interval: RecurrenceInterval) => {
    return {
      key: interval,
      label: <KBText>{getRecurrenceIntervalDescription(interval)}</KBText>,
    }
  })
}

export enum RecurrenceByMonthInterval {
  /**
   * @description 按每月的第几天
   */
  day,
  /**
   * @description 按每月的第几个周几
   */
  week,
}

export enum RecurrenceByYearInterval {
  /**
   * @description 按每年的几月几日
   */
  day,
  /**
   * @description 按每年的几月的第几个周几
   */
  week,
}

/**
 * 将 RecurrenceInfo 转换为 RecurringEventDto
 * @param recurrenceInfo
 * @param timeZone
 * @returns
 */
export const recurrenceInfoToDto = (
  recurrenceInfo?: RecurrenceInfo,
  timeZone?: string
): RecurringEventDto | undefined => {
  let recurringEventDto: RecurringEventDto | undefined = undefined
  if (
    recurrenceInfo &&
    recurrenceInfo?.intervalType !== RecurrenceInterval.noRecurrence
  ) {
    recurringEventDto = {
      interval: recurrenceInfo.interval as number,
      freq: recurrenceInfo.intervalType as RecurringEventDtoFreq,
      until: recurrenceInfo?.endDate?.format('YYYY-MM-DD'),
      tzid: timeZone,
      bymonthday:
        [RecurrenceInterval.month, RecurrenceInterval.year].includes(
          recurrenceInfo?.intervalType
        ) && recurrenceInfo.monthDay
          ? [recurrenceInfo.monthDay]
          : undefined,
      bymonth:
        recurrenceInfo.month !== undefined
          ? [recurrenceInfo.month as RecurringEventDtoBymonth]
          : undefined,
      bysetpos: recurrenceInfo?.monthWeekNumber
        ? [recurrenceInfo?.monthWeekNumber]
        : undefined,
      byweekday:
        recurrenceInfo?.intervalType === RecurrenceInterval.week
          ? (recurrenceInfo?.selectedWeekdays as RecurringEventDtoByweekday[])
          : recurrenceInfo?.monthWeekDay !== undefined
            ? [recurrenceInfo?.monthWeekDay as RecurringEventDtoByweekday]
            : undefined,
    }
  }
  return recurringEventDto
}

/**
 * 根据重复条件获取具体的文案描述
 */
export function getRecurrenceDescription(recurrenceInfo?: RecurrenceInfo) {
  if (!recurrenceInfo) return ''

  const { startDate: startTime, intervalType: type } = recurrenceInfo

  if (
    type === RecurrenceInterval.day &&
    recurrenceInfo.endDate &&
    recurrenceInfo.interval
  ) {
    return tr('recurrence.byDayDescription', {
      interval: recurrenceInfo.interval,
      endDate: formatDate(recurrenceInfo.endDate.toDate()),
    })
  } else if (
    type === RecurrenceInterval.week &&
    recurrenceInfo.endDate &&
    recurrenceInfo.interval &&
    recurrenceInfo.selectedWeekdays?.length
  ) {
    return tr('recurrence.byWeekDescription', {
      interval: recurrenceInfo.interval,
      weekdays: recurrenceInfo.selectedWeekdays
        ?.map((weekday) => nameOfDay(weekday, { weekdayFormat: 'short' }))
        .join(', '),
      endDate: formatDate(recurrenceInfo.endDate.toDate()),
    })
  } else if (type === RecurrenceInterval.month) {
    if (
      recurrenceInfo.monthRecurrenceByMonthInterval ===
      RecurrenceByMonthInterval.day
    ) {
      return tr('recurrence.byMonthDayDescription', {
        interval: recurrenceInfo.interval,
        day: startTime.date(),
        endDate: formatDate(recurrenceInfo.endDate.toDate()),
      })
    } else {
      return tr('recurrence.byMonthWeekDescription', {
        interval: recurrenceInfo.interval,
        week: startTime.isoWeek() - startTime.startOf('month').isoWeek(),
        weekday: nameOfDay(startTime.weekday(), { weekdayFormat: 'short' }),
        endDate: formatDate(recurrenceInfo.endDate.toDate()),
      })
    }
  } else if (type === RecurrenceInterval.year) {
    if (
      recurrenceInfo.yearRecurrenceByYearInterval ===
      RecurrenceByYearInterval.day
    ) {
      return tr('recurrence.byYearMonthDayDescription', {
        month: startTime.month() + 1,
        day: startTime.date(),
        endDate: formatDate(recurrenceInfo.endDate.toDate()),
      })
    } else {
      return tr('recurrence.byYearMonthWeekDescription', {
        month: startTime.month() + 1,
        week: startTime.isoWeek() - startTime.startOf('month').isoWeek(),
        weekday: nameOfDay(startTime.weekday(), { weekdayFormat: 'short' }),
        endDate: formatDate(recurrenceInfo.endDate.toDate()),
      })
    }
  } else {
    return ''
  }
}

/**
 * 根据RRule字符串组装成RecurrenceInfo格式
 */
export const fillRecurrenceInfoByRrule = (
  rruleString?: string,
  startDate?: string
): RecurrenceInfo | undefined => {
  if (!rruleString) return
  const repeatInfo = rrulestr(rruleString).options

  const { freq } = repeatInfo

  const common = {
    interval: repeatInfo.interval,
    startDate: dayjs(startDate),
    endDate: dayjs(repeatInfo.until),
    intervalType: repeatInfo.freq as number,
  }

  if (freq === Frequency.DAILY) {
    // 按天
    return {
      ...common,
    }
  } else if (freq === Frequency.WEEKLY) {
    // 按周
    return {
      ...common,
      selectedWeekdays: repeatInfo.byweekday,
    }
  } else if (freq === Frequency.MONTHLY) {
    if (repeatInfo.bymonthday?.length) {
      // 按月-第几天
      return {
        ...common,
        monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.day,
        monthDay: repeatInfo.bymonthday?.[0],
      }
    } else {
      // 按月-第几周的周几
      return {
        ...common,
        monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.week,
        monthWeekNumber: repeatInfo.bysetpos?.[0],
        monthWeekDay: repeatInfo.byweekday?.[0],
      }
    }
  } else if (freq === Frequency.YEARLY) {
    if (repeatInfo.bymonthday?.length) {
      // 按年-几月几日
      return {
        ...common,
        yearRecurrenceByYearInterval: RecurrenceByYearInterval.day,
        month: repeatInfo.bymonth?.[0],
        monthDay: repeatInfo.bymonthday?.[0],
      }
    } else {
      // 按年-几月第几个周几
      return {
        ...common,
        yearRecurrenceByYearInterval: RecurrenceByYearInterval.week,
        month: repeatInfo.bymonth?.[0],
        monthWeekNumber: repeatInfo.bysetpos?.[0],
        monthWeekDay: repeatInfo.byweekday?.[0],
      }
    }
  }
}
