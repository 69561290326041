import React from 'react'

import { ReservationShowEntity, ResourceEntity } from '@/api'
import { KBForm, KBIcon, KBSelect, KBText } from '@/components/atoms'
import { GroupEditType } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'

interface ReminderSelectProps {
  resourcesList: ResourceEntity[]
  reservationInfo?: ReservationShowEntity
  groupEditType?: GroupEditType
  errorsMessages?: string
}
/**
 * 预定提醒组件
 */
const ReminderSelect: React.FC<ReminderSelectProps> = (props) => {
  const { resourcesList, reservationInfo, groupEditType, errorsMessages } =
    props

  const ReminderOptions = [
    {
      value: 0,
      label: <KBText>{tr('reservation.reminder.noReminder')} </KBText>,
    },
    {
      value: 5,
      label: (
        <KBText>{tr('reservation.reminderMinutes', { minute: 5 })} </KBText>
      ),
    },
    {
      value: 10,
      label: (
        <KBText>{tr('reservation.reminderMinutes', { minute: 10 })} </KBText>
      ),
    },
    {
      value: 15,
      label: (
        <KBText>{tr('reservation.reminderMinutes', { minute: 15 })} </KBText>
      ),
    },
    {
      value: 30,
      label: (
        <KBText>{tr('reservation.reminderMinutes', { minute: 30 })} </KBText>
      ),
    },
    {
      value: 60,
      label: <KBText>{tr('reservation.reminderHours', { hour: 1 })} </KBText>,
    },
  ]

  // const validateReminder = (reminderBefore: number | undefined) => {
  //   if (reminderBefore) {
  //     const cannotSelectReminders = resourcesList.filter(
  //       (i) =>
  //         !(i.reservation_form_rule?.rules as TReservationFormRule)?.reminder
  //     )
  //     if (cannotSelectReminders?.length) {
  //       return Promise.reject(
  //         new Error(
  //           `${cannotSelectReminders.map((i) => i.name).join('、')}${tr('[intl]不允许选择提醒')}`
  //         )
  //       )
  //     }
  //     return Promise.resolve()
  //   } else {
  //     return Promise.resolve()
  //   }
  // }

  const showReminder = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      return rules?.reminder
    })
  }, [resourcesList])

  const disabled = useMemo(() => {
    if (!reservationInfo) return false

    const { is_group } = reservationInfo

    return groupEditType === 'current'
  }, [reservationInfo])

  return showReminder ? (
    <KBForm.Item
      name="reminderBefore"
      label={<KBIcon name="alarm-clock" size={24} />}
      dependencies={['resourceIds']}
      // rules={[
      //   {
      //     validator: (_, reminderBefore) => validateReminder(reminderBefore),
      //   },
      // ]}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <KBSelect
        options={ReminderOptions}
        defaultValue={0}
        disabled={disabled}
        className="tw-w-full"
      />
    </KBForm.Item>
  ) : null
}

export default ReminderSelect
