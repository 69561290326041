/**
 * 格式化百分比
 * @param percentage
 * @param fixed
 * @returns
 */
export function formatPercentage(percentage: number, fixed?: number): string {
  return `${percentage.toFixed(fixed || 0)}%`
}

/**
 * 格式化为百分比
 * @param count
 * @param total
 * @param fixed
 * @returns
 */
export function formatAsPercent(
  count: number,
  total: number,
  fixed?: number
): string {
  if (total === 0) {
    return formatPercentage(0, fixed)
  }
  return formatPercentage((count / total) * 100, fixed)
}

/**
 * 格式化价格
 *
 * @example
 * formatPrice(100) // $100.00
 * formatPrice(100, 'CNY') // ¥100.00
 * formatPrice(100, 'EUR', 'de-DE') // €100.00
 * @param price
 * @param currencyCode
 * @param locale
 * @returns
 */
export function formatPrice(
  price: number,
  currencyCode: string = 'USD',
  locale: string = 'en-US'
): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
  }).format(price)
}
