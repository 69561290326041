import { Dayjs, ITimeSegment } from '@wox/dayjs'
import { Variant } from 'antd/es/form/hooks/useVariants'

import { KBDatePicker } from '@/components/atoms/KBDatePicker'
import { KBIcon } from '@/components/atoms/KBIcon'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { KBSpace } from '@/components/atoms/KBSpace'
import { KBText } from '@/components/atoms/KBText'
import { KBTimeSelectPicker } from '@/components/atoms/KBTimeSelectPicker'
import { TimeRangePickerProps } from 'antd'

export enum RangePickerMode {
  /**
   * 按时间自由选择，包括日期
   */
  DateTimePicker,
  /**
   * 按时间自由选择，只包括时间,不包括日期选择
   */
  TimePicker,
  /**
   * 按日期选择
   */
  DatePicker,
  /**
   * 跨日期选择
   */
  CrossDatePicker,
  /**
   * 按时间段选择
   */
  TimeSegmentPicker,
}

// interface SelectBarProps {
//   mode?: RangePickerMode
//   getCurTypeKey: (typeKey: SelectBarProps['mode']) => void
// }

interface AllPickerProps
  extends Omit<TimeRangePickerProps, 'value' | 'onChange'> {
  onChange: (startTime?: Dayjs, endTime?: Dayjs) => void
  value?: [Dayjs | undefined, Dayjs | undefined]
  className?: string
  selectVariant?: Variant
  disabled?: boolean
}

export interface KBTimeRangePickerProps
  extends Omit<TimeRangePickerProps, 'value' | 'onChange' | 'mode'> {
  mode?: RangePickerMode
  /**
   * 如果按时间段选择，需要传入时间段
   */
  timeSegment?: ITimeSegment
  onChange: (startTime?: Dayjs, endTime?: Dayjs) => void
  value?: [Dayjs | undefined, Dayjs | undefined]
  className?: string
  selectVariant?: Variant
  disabled?: boolean
}

// Select Bar配置
// const selectBarItems: ItemType[] = [1
//   {
//     key: RangePickerMode.ByTimePicker,
//     icon: <KBIcon name="calendar-clock" size={18} />,
//     label: <KBText weight="middle">By Time</KBText>,
//   },
//   {
//     key: RangePickerMode.ByTimePickerOnlyTime,
//     icon: <KBIcon name="calendar-clock" size={18} />,
//     label: <KBText weight="middle">By Time</KBText>,
//   },
//   {
//     key: RangePickerMode.ByDayPicker,
//     icon: <KBIcon name="calendar-clock" size={18} />,
//     label: <KBText weight="middle">By Day</KBText>,
//   },
//   {
//     key: RangePickerMode.CrossDatePicker,
//     icon: <KBIcon name="calendar-clock" size={18} />,
//     label: <KBText weight="middle">Cross date</KBText>,
//   },
//   {
//     key: RangePickerMode.AfternoonPicker,
//     icon: <KBIcon name="calendar-clock" size={18} />,
//     label: <KBText weight="middle">Afternoon</KBText>,
//   },
// ]

// function SelectBar(props: SelectBarProps) {
//   const { mode, getCurTypeKey } = props
//   const onItemClick = ({ key }: { key: number }) => {
//     getCurTypeKey(key as SelectBarProps['mode'])
//   }
//   return (
//     <KBDropdown
//       menu={{ items: selectBarItems, onClick: onItemClick }}
//       placement="bottomLeft"
//       arrow
//     >
//       <KBButton icon={<KBIcon name="calendar-clock" size={18} />}>
//         {selectBarItems[mode].label}
//       </KBButton>
//     </KBDropdown>
//   )
// }

/**
 * 替换一组dayjs对象的日期部分。
 * @param correctDate 正确的日期。
 * @param dateTimes 需要替换日期的dayjs对象数组。
 * @returns 返回替换日期后的dayjs对象数组。
 */
export function replaceDateForTimes(
  correctDate: Dayjs,
  dateTimes: Dayjs[]
): Dayjs[] {
  return dateTimes.map((dateTime) =>
    dateTime
      .year(correctDate.year())
      .month(correctDate.month())
      .date(correctDate.date())
  )
}

/**
 * 替换一组dayjs对象的时刻部分。
 * @param correctTime 正确的时刻。
 * @param timeTimes 需要替换时刻的dayjs对象数组。
 * @returns 返回替换时刻后的dayjs对象数组。
 */
export function replaceTimeForDates(
  correctTime: Dayjs,
  timeTimes: Dayjs[]
): Dayjs[] {
  return timeTimes.map((timeTime) =>
    timeTime
      .hour(correctTime.hour())
      .minute(correctTime.minute())
      .second(correctTime.second())
  )
}

/**
 * 选择任意时间段，包括日期选择和时间选择
 * @param props
 * @returns
 */
function DateTimePicker(props: AllPickerProps) {
  const {
    onChange,
    value = [],
    className,
    selectVariant,
    allowClear,
    disabled,
    disabledDate,
  } = props
  const [startTime, setStartTime] = useState(value[0])
  const [endTime, setEndTime] = useState(value[1])
  const [date, setDate] = useState(value[0]?.startOf('day'))
  const [startTimeForRange, setStartTimeForRange] = useState(value[0])

  useEffect(() => {
    if (date && startTime && endTime) {
      const [resStartTime, resEndTime] = replaceDateForTimes(date, [
        startTime,
        endTime,
      ]) as [Dayjs, Dayjs]
      if (resStartTime.isAfter(resEndTime)) {
        kbMessage.warning('开始时间不能晚于结束时间')
        setStartTime(undefined)
        setEndTime(undefined)
        setStartTimeForRange(undefined)
      } else {
        setStartTimeForRange(resStartTime)
        onChange(resStartTime, resEndTime)
      }
    }
  }, [startTime, endTime, date])

  // FIXME: 外部修改value时，组件不会刷新
  // useEffect(() => {
  //   if (!value?.length) return
  //   setStartTime(value[0])
  //   setEndTime(value[1])
  //   setDate(value[0]?.startOf('day'))
  //   setStartTimeForRange(value[0])
  // }, [value])
  return (
    <KBSpace wrap className="tw-flex-shrink-0">
      <KBDatePicker
        value={date}
        onChange={(date) => setDate(date)}
        className={className}
        allowClear={allowClear}
        disabled={disabled}
        disabledDate={disabledDate}
      />
      <KBSpace>
        <KBTimeSelectPicker
          data-testid="start-time-picker"
          value={startTime}
          onChange={(date) => setStartTime(date)}
          className={className}
          selectVariant={selectVariant}
          disabled={disabled}
        />
        <KBIcon name="minus" />
        <KBTimeSelectPicker
          data-testid="end-time-picker"
          startTimeForRange={startTimeForRange}
          value={endTime}
          onChange={(date) => setEndTime(date)}
          className={className}
          selectVariant={selectVariant}
          disabled={disabled}
        />
      </KBSpace>
    </KBSpace>
  )
}

function TimePicker(props: AllPickerProps) {
  const { onChange, value = [], disabled, className } = props
  // const [inValue, setInValue] = useState(value || [])
  const [startTime, setStartTime] = useState(value[0])
  const [endTime, setEndTime] = useState(value[1])
  const [startTimeForRange, setStartTimeForRange] = useState(value[0])

  useEffect(() => {
    const startTime = value[0]
    const endTime = value[1]
    if (startTime && endTime) {
      if (startTime.isAfter(endTime)) {
        kbMessage.warning(tr('common.startTimeAfterEndTime'))
        setStartTime(undefined)
        setEndTime(undefined)
        setStartTimeForRange(undefined)
      } else {
        setStartTimeForRange(startTime)
        setStartTime(startTime)
        setEndTime(endTime)
      }
    }
  }, [value])

  // console.log(
  //   '>>> TimePicker value',
  //   startTime?.format(),
  //   endTime?.format(),
  //   value
  // )
  return (
    <KBSpace wrap>
      <KBTimeSelectPicker
        value={startTime}
        data-testid="start-time-picker"
        onChange={(date) => {
          setStartTime(date)
          onChange(date, endTime)
        }}
        disabled={disabled}
        className={className}
      />
      <KBIcon name="minus" />
      <KBTimeSelectPicker
        startTimeForRange={startTimeForRange}
        value={endTime}
        data-testid="end-time-picker"
        onChange={(date) => {
          setEndTime(date)
          onChange(startTime, date)
        }}
        disabled={disabled}
        className={className}
      />
    </KBSpace>
  )
}

function DatePicker(props: AllPickerProps) {
  const { onChange, value = [], disabled, className, disabledDate } = props
  const [startDate, setStartDate] = useState(value[0])
  const [endDate, setEndDate] = useState(value[1])

  console.log(
    '>>> DatePicker value',
    value[0]?.format('MM-DD'),
    value[1]?.format('MM-DD'),
    startDate?.format('MM-DD'),
    endDate?.format('MM-DD')
  )
  useEffect(() => {
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        kbMessage.warning('开始时间不能晚于结束时间')
        setStartDate(undefined)
        setEndDate(undefined)
      } else {
        onChange(startDate, endDate)
      }
    }
  }, [startDate, endDate])
  return (
    <KBSpace wrap>
      <KBDatePicker
        disabled={disabled}
        data-testid="start-date-picker"
        value={startDate}
        onChange={(date) => setStartDate(date)}
        className={className}
        disabledDate={disabledDate}
      />
      <KBIcon name="minus" />
      <KBDatePicker
        disabled={disabled}
        value={endDate}
        data-testid="end-date-picker"
        onChange={(date) => setEndDate(date.endOf('day'))}
        className={className}
        disabledDate={disabledDate}
      />
    </KBSpace>
  )
}

function CrossDatePicker(props: AllPickerProps) {
  const {
    onChange,
    value = [],
    allowClear,
    disabled,
    className,
    selectVariant,
    disabledDate,
  } = props
  // const [inValue, setInValue] = useState(value?.[0] ? value : [])
  const [startTime, setStartTime] = useState(value[0])
  const [endTime, setEndTime] = useState(value[1])
  const [startDate, setStartDate] = useState(value[0]?.startOf('day'))
  const [endDate, setEndDate] = useState(value[1]?.startOf('day'))
  const [startTimeForRange, setStartTimeForRange] = useState(value[0])

  useEffect(() => {
    if (startDate && endDate && startTime && endTime) {
      const [resStart] = replaceTimeForDates(startTime, [startDate])
      const [resEnd] = replaceTimeForDates(endTime, [endDate])
      if (resStart.isAfter(resEnd)) {
        kbMessage.warning('开始时间不能晚于结束时间')
        setStartTime(undefined)
        setEndTime(undefined)
        setStartTimeForRange(undefined)
      } else {
        setStartTimeForRange(resStart)
        onChange(resStart, resEnd)
      }
    }
  }, [startTime, endTime, startDate, endDate])
  return (
    <KBSpace wrap>
      <KBSpace>
        <KBDatePicker
          size="large"
          data-testid="start-date-picker"
          className={`tw-w-[120px] ${className}`}
          value={startDate}
          onChange={(date) => setStartDate(date)}
          allowClear={allowClear}
          disabled={disabled}
          disabledDate={disabledDate}
        />
        <KBTimeSelectPicker
          value={startTime}
          data-testid="start-time-picker"
          onChange={(date) => setStartTime(date)}
          className="tw-w-[120px]"
          disabled={disabled}
          selectVariant={selectVariant}
        />
      </KBSpace>
      <KBIcon name="minus" />
      <KBSpace>
        <KBDatePicker
          size="large"
          value={endDate}
          className={`tw-w-[120px] ${className}`}
          data-testid="end-date-picker"
          disabled={disabled}
          onChange={(date) => setEndDate(date)}
          disabledDate={disabledDate}
        />
        <KBTimeSelectPicker
          // startTimeForRange={startTimeForRange}
          value={endTime}
          className="tw-w-[120px]"
          disabled={disabled}
          onChange={(date) => setEndTime(date)}
          selectVariant={selectVariant}
          data-testid="end-time-picker"
        />
      </KBSpace>
    </KBSpace>
  )
}

/**
 * 时间段选择器， 按时段选择
 * @param props
 * @returns
 */
function TimeSegmentPicker(
  props: AllPickerProps & { timeSegment: ITimeSegment }
) {
  const { onChange, timeSegment, value = [], allowClear, disabled } = props
  const [date, setDate] = useState(value[0]?.startOf('day'))
  const getTimeDate = (specificDate: Dayjs, timeString: string) => {
    const [hour, minute] = timeString.split(':').map(Number)
    return specificDate.hour(hour).minute(minute).second(0)
  }
  useEffect(() => {
    if (date) {
      onChange(
        getTimeDate(date, timeSegment.start),
        getTimeDate(date, timeSegment.end)
      )
    }
  }, [date])
  return (
    <KBSpace>
      <KBDatePicker
        value={date}
        onChange={(date) => setDate(date)}
        allowClear={allowClear}
        disabled={disabled}
      />
      <KBText className="tw-ml-3">
        {timeSegment.name} {`${timeSegment.start} - ${timeSegment.end}`}
      </KBText>
    </KBSpace>
  )
}

export function KBTimeRangePicker(props: KBTimeRangePickerProps) {
  const {
    mode = RangePickerMode.TimePicker,
    timeSegment = { start: '14:00', end: '21:00', name: 'PM' },
    value,
    className,
    onChange,
    selectVariant,
    ...restProps
  } = props

  console.log('>>> KBTimeRangePicker value', value?.[0]?.format('MM-DD HH:mm'))
  // const [curMode, setCurMode] = useState<SelectBarProps['mode']>(mode)
  // const getCurMode = (mode: SelectBarProps['mode']) => {
  //   setCurMode(mode)
  // }
  // console.log('KBTimeRangePicker', value?.[0]?.format(), value?.[1]?.format())

  switch (mode) {
    case RangePickerMode.DateTimePicker:
      return (
        <DateTimePicker
          value={value}
          onChange={onChange}
          className={className}
          selectVariant={selectVariant}
          {...restProps}
        />
      )
    case RangePickerMode.TimePicker:
      return (
        <TimePicker
          value={value}
          onChange={onChange}
          className={className}
          {...restProps}
        />
      )
    case RangePickerMode.DatePicker:
      return (
        <DatePicker
          value={value}
          onChange={onChange}
          className={className}
          {...restProps}
        />
      )
    case RangePickerMode.CrossDatePicker:
      return (
        <CrossDatePicker
          value={value}
          onChange={onChange}
          className={className}
          selectVariant={selectVariant}
          {...restProps}
        />
      )
    case RangePickerMode.TimeSegmentPicker:
      return (
        <TimeSegmentPicker
          value={value}
          onChange={onChange}
          timeSegment={timeSegment}
          className={className}
          {...restProps}
        />
      )
  }
}
