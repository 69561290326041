import { generateFromString } from 'generate-avatar'

import { AuthUserEntity } from '@/api'
import { KBFlex, KBImage, KBSpace, KBTypography } from '@/components/atoms'
const { Text } = KBTypography

import { ChevronRight } from 'lucide-react'
import React from 'react'

interface SpaceItemProps {
  user: AuthUserEntity
  showArrow?: boolean
  onSelect?: () => void
  isHovering?: boolean
  width?: number
}

const SpaceItem: React.FC<SpaceItemProps> = ({
  user,
  showArrow = true,
  onSelect,
  isHovering,
  width = 48,
}) => {
  const spaceName: string = user?.space?.name!
  const space = user?.space
  return (
    <KBFlex className="tw-flex" onClick={onSelect} align="center">
      <KBImage
        preview={false}
        width={width}
        src={
          space?.white_logo ||
          `data:image/svg+xml;utf8,${generateFromString(spaceName)}`
        }
      />
      <KBSpace
        direction="vertical"
        size={3}
        className="tw-ml-3 tw-flex tw-flex-1 tw-text-left"
      >
        <Text strong className="tw-text-left">
          {spaceName}
        </Text>
        <Text type="secondary" ellipsis={true}>
          {spaceName}
        </Text>
      </KBSpace>
      {isHovering && showArrow && <ChevronRight size={16} />}
    </KBFlex>
  )
}

export default SpaceItem
