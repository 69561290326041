// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { VisitorConfigAdminFindByLocationIdQueryResponse, VisitorConfigAdminFindByLocationIdQueryParams, VisitorConfigAdminFindByLocationId401 } from "../models/VisitorConfigAdminFindByLocationId";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type VisitorConfigAdminFindByLocationIdClient = typeof client<VisitorConfigAdminFindByLocationIdQueryResponse, VisitorConfigAdminFindByLocationId401, never>;
type VisitorConfigAdminFindByLocationId = {
    data: VisitorConfigAdminFindByLocationIdQueryResponse;
    error: VisitorConfigAdminFindByLocationId401;
    request: never;
    pathParams: never;
    queryParams: VisitorConfigAdminFindByLocationIdQueryParams;
    headerParams: never;
    response: VisitorConfigAdminFindByLocationIdQueryResponse;
    client: {
        parameters: Partial<Parameters<VisitorConfigAdminFindByLocationIdClient>[0]>;
        return: Awaited<ReturnType<VisitorConfigAdminFindByLocationIdClient>>;
    };
};
export const visitorConfigAdminFindByLocationIdQueryKey = (params: VisitorConfigAdminFindByLocationId["queryParams"]) => ["api", "v5", "admin", "visitor_configs", "find-by-location-id", ...(params ? [params] : [])] as const;
export type VisitorConfigAdminFindByLocationIdQueryKey = ReturnType<typeof visitorConfigAdminFindByLocationIdQueryKey>;
export function visitorConfigAdminFindByLocationIdQueryOptions(params: VisitorConfigAdminFindByLocationId["queryParams"], options: VisitorConfigAdminFindByLocationId["client"]["parameters"] = {}) {
    const queryKey = visitorConfigAdminFindByLocationIdQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<VisitorConfigAdminFindByLocationId["data"], VisitorConfigAdminFindByLocationId["error"]>({
                method: "get",
                url: `/api/v5/admin/visitor_configs/find-by-location-id`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取VisitorConfigEntity详情
 * @link /api/v5/admin/visitor_configs/find-by-location-id
 */
export function useVisitorConfigAdminFindByLocationId<TData = VisitorConfigAdminFindByLocationId["response"], TQueryData = VisitorConfigAdminFindByLocationId["response"], TQueryKey extends QueryKey = VisitorConfigAdminFindByLocationIdQueryKey>(params: VisitorConfigAdminFindByLocationId["queryParams"], options: {
    query?: Partial<QueryObserverOptions<VisitorConfigAdminFindByLocationId["response"], VisitorConfigAdminFindByLocationId["error"], TData, TQueryData, TQueryKey>>;
    client?: VisitorConfigAdminFindByLocationId["client"]["parameters"];
} = {}): UseQueryResult<TData, VisitorConfigAdminFindByLocationId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? visitorConfigAdminFindByLocationIdQueryKey(params);
    const query = useQuery({
        ...visitorConfigAdminFindByLocationIdQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, VisitorConfigAdminFindByLocationId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const visitorConfigAdminFindByLocationIdSuspenseQueryKey = (params: VisitorConfigAdminFindByLocationId["queryParams"]) => ["api", "v5", "admin", "visitor_configs", "find-by-location-id", ...(params ? [params] : [])] as const;
export type VisitorConfigAdminFindByLocationIdSuspenseQueryKey = ReturnType<typeof visitorConfigAdminFindByLocationIdSuspenseQueryKey>;
export function visitorConfigAdminFindByLocationIdSuspenseQueryOptions(params: VisitorConfigAdminFindByLocationId["queryParams"], options: VisitorConfigAdminFindByLocationId["client"]["parameters"] = {}) {
    const queryKey = visitorConfigAdminFindByLocationIdSuspenseQueryKey(params);
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<VisitorConfigAdminFindByLocationId["data"], VisitorConfigAdminFindByLocationId["error"]>({
                method: "get",
                url: `/api/v5/admin/visitor_configs/find-by-location-id`,
                params,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary 获取VisitorConfigEntity详情
 * @link /api/v5/admin/visitor_configs/find-by-location-id
 */
export function useVisitorConfigAdminFindByLocationIdSuspense<TData = VisitorConfigAdminFindByLocationId["response"], TQueryKey extends QueryKey = VisitorConfigAdminFindByLocationIdSuspenseQueryKey>(params: VisitorConfigAdminFindByLocationId["queryParams"], options: {
    query?: Partial<UseSuspenseQueryOptions<VisitorConfigAdminFindByLocationId["response"], VisitorConfigAdminFindByLocationId["error"], TData, TQueryKey>>;
    client?: VisitorConfigAdminFindByLocationId["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, VisitorConfigAdminFindByLocationId["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? visitorConfigAdminFindByLocationIdSuspenseQueryKey(params);
    const query = useSuspenseQuery({
        ...visitorConfigAdminFindByLocationIdSuspenseQueryOptions(params, clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, VisitorConfigAdminFindByLocationId["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}