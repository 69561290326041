import { useAuthLogout } from '@/api'
import {
  IconName,
  KBAvatar,
  KBDivider,
  KBFlex,
  KBIcon,
  KBPopover,
  KBTypography,
} from '@/components/atoms'
import {
  currentSessionTokenAtom,
  currentUserDataAtom,
  localeAtom,
  logoutAtom,
} from '@/store'
import { getUserAvatar } from '@/utils/avatarUrl'
import { SUPPORTED_LOCALES } from '@/utils/localization/supported_locales'
import { Popover } from 'antd'
import { useAtom, useSetAtom } from 'jotai'

const UserLayoutAvatar: React.FC = () => {
  const navigate = useNavigate()
  const [locale, setLocale] = useAtom(localeAtom)
  const logout = useSetAtom(logoutAtom)
  const [currentUser] = useAtom(currentUserDataAtom)
  const [currentSessionToken] = useAtom(currentSessionTokenAtom)
  const { mutate: logoutUser } = useAuthLogout()

  interface ContentItemProps {
    label: string | JSX.Element
    icon: IconName
    itemKey: string
    onItemClick: () => void
  }

  const onChangeLanguage = (key: string) => {
    const selectedLocale = SUPPORTED_LOCALES.find((lang) => lang.value === key)
    setLocale(selectedLocale?.value!)
  }
  const LanguageContent: React.FC = () => {
    return (
      <>
        {SUPPORTED_LOCALES.map((language, index) => (
          <KBTypography.Text
            key={index}
            onClick={() => onChangeLanguage(language.value)}
            className="tw-block tw-w-[189px] tw-cursor-pointer  tw-rounded tw-px-3 tw-py-[5px] hover:tw-bg-[var(--wox-color-bg-text-hover)]"
          >
            {language.name}
          </KBTypography.Text>
        ))}
      </>
    )
  }
  const items: ContentItemProps[] = [
    {
      label: tr('user.settingForm.account'),
      icon: 'circle-user-round',
      itemKey: 'user',
      onItemClick() {
        navigate('/user/users/settings')
      },
    },
    {
      label: (
        <KBPopover
          content={<LanguageContent />}
          placement="rightTop"
          arrow={false}
          overlayInnerStyle={{
            padding: '4px',
          }}
          align={{
            offset: [20, 10],
          }}
        >
          {tr('common.switchLanguage')}
        </KBPopover>
      ),
      icon: 'globe',
      itemKey: 'user',
      onItemClick() {
        navigate('/user/users/settings')
      },
    },
    {
      label: tr('menu.account.logout'),
      itemKey: 'logout',
      icon: 'log-out',
      onItemClick() {
        currentSessionToken &&
          logoutUser(
            {
              data: {
                sessionToken: currentSessionToken,
              },
            },
            {
              onSettled: () => {
                logout()
                window.location.assign('/login')
              },
            }
          )
      },
    },
  ]

  const ContentItem: React.FC<ContentItemProps> = (props) => {
    const { label, icon, itemKey: itemKey, onItemClick } = props

    return (
      <>
        {itemKey === 'logout' && <KBDivider className="tw-my-1" />}
        <KBFlex
          className="tw-cursor-pointer tw-rounded tw-px-3 tw-py-[5px] hover:tw-bg-[var(--wox-color-bg-text-hover)]"
          gap={8}
          align="center"
          onClick={() => onItemClick()}
        >
          <KBIcon name={icon} />
          <KBTypography.Text>{label}</KBTypography.Text>
        </KBFlex>
      </>
    )
  }
  return (
    <Popover
      placement="rightTop"
      arrow={false}
      content={
        <div>
          {items.map((item, index) => (
            <ContentItem {...item} key={index} />
          ))}
        </div>
      }
      align={{
        offset: [20, 0],
      }}
      overlayInnerStyle={{
        padding: '4px',
      }}
    >
      <KBAvatar size={32} src={getUserAvatar(currentUser)} />
    </Popover>
  )
}

export default UserLayoutAvatar
