import NiceModal, { useModal } from '@ebay/nice-modal-react'
import dayjs, { Dayjs, findClosestTimeWithStep } from '@wox/dayjs'
import { useAtom } from 'jotai'

import {
  CreateResourceReservationDto,
  ReservationCustomFieldValue,
  ReservationShowEntity,
  ResourceEntity,
  ResourceReservationCreateMutationResponse,
  SimpleResourceReservationEntity,
  UpdateResourceReservationDto,
  UpdateResourceReservationDtoAction,
  useResourceIndex,
  useResourceReservationAdminCreate,
  useResourceReservationAdminShow,
  useResourceReservationAdminUpdate,
  useResourceReservationCreate,
  useResourceReservationShow,
  useResourceReservationUpdate,
} from '@/api'
import { KBDivider, KBForm, KBModal, KBSkeleton } from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import AttendeesSelect from '@/components/molecules/KBResourceReservationForm/components/AttendeesSelect'
import CloudVideoSelect, {
  CloudVideoValueProps,
} from '@/components/molecules/KBResourceReservationForm/components/CloudVideoSelect'
import CustomFields from '@/components/molecules/KBResourceReservationForm/components/CustomFields'
import FormTotalAlert from '@/components/molecules/KBResourceReservationForm/components/FormTotalAlert'
import HostSelect from '@/components/molecules/KBResourceReservationForm/components/HostSelect'
import NoteInput from '@/components/molecules/KBResourceReservationForm/components/NoteInput'
import ReminderSelect from '@/components/molecules/KBResourceReservationForm/components/ReminderSelect'
import ResourceSelect from '@/components/molecules/KBResourceReservationForm/components/ResourceSelect'
import ServiceSelect, {
  ServiceValueItem,
} from '@/components/molecules/KBResourceReservationForm/components/ServiceSelect'
import SubjectInput from '@/components/molecules/KBResourceReservationForm/components/SubjectInput'
import TimeSlotSelect, {
  TimeSlotItem,
} from '@/components/molecules/KBResourceReservationForm/components/TimeSlotSelect'
import {
  fillRecurrenceInfoByRrule,
  recurrenceInfoToDto,
} from '@/components/organisms/KBRecurrenceEditModal/constants'
import { ReservationTimeModeEnum } from '@/enums/modelEnums'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { currentUserDataAtom } from '@/store'
import { MAX_PAGINATION_LIMIT } from '@/utils/constants'
import { isValidEmail } from '@/utils/string'
import { isUserClient } from '@/utils/userEnvironment'
import { debounce } from 'radash'

export type GroupEditType = 'current' | 'groupMaster'

export interface KBResourceReservationFormProps {
  recurringEditType?: UpdateResourceReservationDtoAction
  groupEditType?: GroupEditType
  resourceIds?: number[]
  locationId?: number
  startAt?: Dayjs
  endAt?: Dayjs
  timeZone?: string
  timeMode?: ReservationTimeModeEnum
  /**
   * 预定信息--编辑或者展示时传
   */
  reservationInfo?: ReservationShowEntity
  onSuccess?: () => void
}

export interface FormValueProps {
  cloudVideoProviders?: CloudVideoValueProps
  host?: {
    hostId?: number
    isPrivate?: boolean
  }
  notes?: string
  reminderBefore?: number
  resourceIds: number[]
  services?: ServiceValueItem[]
  subject: string
  timeMode: TimeSlotItem
  userIds?: Array<string | number>
  // 自定义字段需要用到
  customFieldsValue?: {
    [key: string]: string | string[] | number[] | boolean | number | undefined
  }
}

/**
 * 如果外部传入的时间部分和规范，自动整理
 */
const formatPropsTime = (time: Dayjs) => {
  const minute = time.minute()
  if (minute < 30) {
    return time.startOf('hour')
  } else if (minute > 30) {
    return time.startOf('hour').add(0.5, 'hour')
  }
  return time
}

/**
 * 预定表单组件
 * @param locationId 分店ID
 * @param startAt 预定开始时间
 * @param endAt 预定结束时间
 * @param recurringEditType 周期性预定时，修改类型
 * @param groupEditType 批量预定时，修改类型
 * @param onSuccess 成功回调
 */
// const resourceIds = [5, 6]
// const startAt = dayjs('2024-10-11 13:00:00')
// const endAt = dayjs('2024-10-12 13:00:00')
// const timeMode = ReservationTimeModeEnum.crossDay

function KBResourceReservationForm(props: KBResourceReservationFormProps) {
  const step = 30 // 时间间隔
  const {
    resourceIds,
    startAt,
    endAt,
    timeMode = ReservationTimeModeEnum.free,

    locationId,
    recurringEditType,
    groupEditType,
    timeZone,
    reservationInfo: reservationData,
    onSuccess,
  } = props

  const [realStartAt, setRealStartAt] = useState(startAt)
  const [realEndAt, setRealEndAt] = useState(endAt)

  useEffect(() => {
    const defaultStartAt = startAt || dayjs()
    const defaultEndAt = endAt || dayjs().add(1, 'hour')
    const _startAt = findClosestTimeWithStep(defaultStartAt, step)
    const _endAt = findClosestTimeWithStep(defaultEndAt, step)
    setRealStartAt(_startAt)
    setRealEndAt(_endAt)
    console.log(
      'KBResourceReservationForm startAt, endAt',
      _startAt.format('MM-DD HH:mm'),
      _endAt.format('MM-DD HH:mm')
    )
  }, [startAt, endAt, step])

  const currentLocationId = locationId || useCurrentLocationId()
  // 只预定一个会议室
  const [isSingleResource, setIsSingleResource] = useState(
    resourceIds?.length === 1
  )

  const [showAlert, setShowAlert] = useState(false)
  // 初始化状态，用于异步加载
  const [initialed, setInitialed] = useState(false)
  const [reservationInfo, setReservationInfo] =
    useState<ReservationShowEntity>()
  const [errorsMessageByBackEnd, setErrorsMessageByBackEnd] =
    useState<Record<string, string>[]>()

  // 获取集合预定的数据
  const { data: groupReservationData, refetch: getGroupReservationData } = (
    isUserClient()
      ? useResourceReservationShow
      : useResourceReservationAdminShow
  )(reservationData?.group_master_id as number, {
    query: {
      enabled: false,
    },
  })

  const createApi = isUserClient()
    ? useResourceReservationCreate
    : useResourceReservationAdminCreate

  const editApi = isUserClient()
    ? useResourceReservationUpdate
    : useResourceReservationAdminUpdate

  const { mutate: createResourceReservation, isPending: creating } = createApi({
    mutation: {
      onSuccess: (data: SimpleResourceReservationEntity[]) => {
        if (data.length >= 1) {
          const reservation = data[0] as SimpleResourceReservationEntity
          // FIXME: 处理预定状态回调
          if (
            reservation.status === 'reserved' ||
            reservation.status === 'upcoming' ||
            reservation.status === 'starting_soon' ||
            reservation.status === 'ongoing' ||
            reservation.status === 'ending_soon'
          ) {
            kbMessage.success(tr('reservation.reservationSuccess'))
          } else if (reservation.status === 'approving') {
            NiceModal.show('KBApprovalModal', {
              start_at: reservation?.start_at,
              end_at: reservation?.end_at,
              resourceId: reservation.resource_id,
            })
          }

          modal.resolve(reservation)
          modal.hide()
          onSuccess?.()
        }
      },
      onError(e) {
        console.log('error---', e)
      },
    },
  })

  const { mutate: updateResourceReservation, isPending: updating } = editApi({
    mutation: {
      onSuccess: () => {
        kbMessage.success(tr('common.updateSuccess'))
        modal.resolve()
        modal.hide()
        onSuccess?.()
      },
      onError(e) {
        console.log('error---', e)
      },
    },
  })

  const validateCallbackFunctions = {
    onSuccess() {
      // setErrorsMessageByBackEnd({
      //   time: validateTimeSlot(),
      // })
      setErrorsMessageByBackEnd([])
    },
    onError(e: ResourceReservationCreateMutationResponse) {
      const errors = e?.response?.data?.errors
      setErrorsMessageByBackEnd(errors)
      if (Array.isArray(errors) && errors.length) {
        console.log('后端烦校验错误', errors)
        setErrorsMessageByBackEnd(errors)
      } else {
        setErrorsMessageByBackEnd([])
      }

      // try {
      //   const messageData = JSON.parse(e?.response?.data?.message)
      //   if (Object.keys(messageData).length) {
      //     if (messageData.time) {
      //       messageData.time.push(...validateTimeSlot())
      //     }
      //     setErrorsMessageByBackEnd(messageData)
      //   } else {
      //     setErrorsMessageByBackEnd({
      //       time: validateTimeSlot(),
      //     })
      //   }
      // } catch (error) {
      //   setErrorsMessageByBackEnd({
      //     time: [e.response.data.message, ...validateTimeSlot()],
      //   })
      // }
    },
  }

  const { mutate: validateCreateData, isPending: isCreateValidating } =
    createApi({
      mutation: validateCallbackFunctions,
    })

  const { mutate: validateUpdateData, isPending: isUpdateValidating } = editApi(
    {
      mutation: validateCallbackFunctions,
    }
  )

  const modal = useModal()
  const [form] = KBForm.useForm<FormValueProps>()
  // TODO 直接获取form.getFieldValue('resourceIds')获取不到，目前先这样处理
  const [resourceIdsState, setResourceIdsState] = useState<number[]>(
    resourceIds || []
  )
  const [currentUser] = useAtom(currentUserDataAtom)
  const currentUserId = currentUser!.id
  const { data: resourcesListData, isLoading: isGetResourceDataLoading } =
    useResourceIndex(
      {
        limit: MAX_PAGINATION_LIMIT,
        location_id: currentLocationId,
      },
      {
        query: {
          staleTime: 0,
        },
      }
    )

  /**
   * 表单值变化时触发
   */
  const onFormFieldsChange = (
    fields: FormValueProps,
    allValues: FormValueProps
  ) => {
    console.log('触发onFormFieldsChange', fields)

    // 资源修改重新获取权限
    if (fields.resourceIds) {
      setResourceIdsState(allValues?.resourceIds || [])
    }

    // 后端校验
    // TODO是否某些字段不需要触发校验?
    validateByBackEnd()

    setTimeout(() => {
      const fields = form.getFieldsError()
      setShowAlert(fields.some((i) => Boolean(i.errors?.length)))
    }, 200)
  }

  /**
   * 时间校验 -- 后端校验的显示优先级会高于前端的校验
   */
  /**
  const validateTimeSlot = () => {
    const { timeMode } = form.getFieldsValue()
    // 资源是否支持当前的时间模式 -- 特殊设置，资源只能按天预定resource_type.book_by_day_only
    const errorList = []
    if (timeMode.timeMode === 'fullDay') {
      const cannotUseFullDayResources = selectedResourceList.filter(
        (i) =>
          !(i.reservation_reserve_rule?.rules as TReservationReserveRule)
            ?.allow_reserve_by_full_day
      )
      if (cannotUseFullDayResources?.length) {
        errorList.push(
          `${cannotUseFullDayResources.map((i) => i.name).join('、')}${tr('[intl]不允许全日预定')}`
        )
      }
    } else if (timeMode.timeMode === 'crossDay') {
      const cannotUseCrossDayResources = selectedResourceList.filter(
        (i) =>
          !(i.reservation_reserve_rule?.rules as TReservationReserveRule)
            ?.allow_reserve_by_across_day || i.resource_type.book_by_day_only
      )
      if (cannotUseCrossDayResources?.length) {
        errorList.push(
          `${cannotUseCrossDayResources.map((i) => i.name).join('、')}${tr('[intl]不允许跨日预定')}`
        )
      }
    } else if (timeMode.timeMode === 'timeslot') {
      const cannotUseTimeSlotResources = selectedResourceList.filter(
        (i) =>
          !(i.reservation_reserve_rule?.rules as TReservationReserveRule)
            ?.allow_reserve_by_timeslot || i.resource_type.book_by_day_only
      )
      if (cannotUseTimeSlotResources?.length) {
        errorList.push(
          `${cannotUseTimeSlotResources.map((i) => i.name).join('、')}${tr('[intl]不允许场次预定')}`
        )
      }
    } else if (timeMode.timeMode === 'free') {
      const cannotUseFreeTimeResources = selectedResourceList.filter(
        (i) => i.resource_type.book_by_day_only
      )
      if (cannotUseFreeTimeResources?.length) {
        errorList.push(
          `${cannotUseFreeTimeResources.map((i) => i.name).join('、')}${tr('[intl]不允许按时段预定')}`
        )
      }
    }

    // 是否支持重复预定
    if (timeMode?.recurrenceInfo) {
      if (
        timeMode?.recurrenceInfo?.intervalType !==
        RecurrenceInterval.noRecurrence
      ) {
        if (selectedResourceList.length > 1) {
          errorList.push(tr('[intl]多资源不允许重复预定'))
        } else {
          const cannotUseRecurringResources = selectedResourceList.filter(
            (i) =>
              !(i.reservation_reserve_rule?.rules as TReservationReserveRule)
                ?.allow_recurring
          )
          if (cannotUseRecurringResources?.length) {
            errorList.push(
              `${cannotUseRecurringResources.map((i) => i.name).join('、')}${tr('[intl]不允许重复预定')}`
            )
          }
        }
      }
    }

    return errorList
  }
  */

  /**
   * 只有一个资源时，判断当前资源的配置自动选中数据
   */
  const setDataWhenOnlyOneResource = () => {
    const resource = selectedResourceList[0]
    // 资源类型-只允许按天预定
    if (resource.resource_type.book_by_day_only) {
      form.setFieldsValue({
        timeMode: {
          timeMode: 'fullDay',
          startAt: dayjs(realStartAt).startOf('day'),
          endAt: dayjs(realEndAt).endOf('day'),
        },
      })
    }
  }

  /**
   * 整合数据结构
   */
  const formatData = (
    values: FormValueProps,
    dryrun: boolean = false
  ): CreateResourceReservationDto | UpdateResourceReservationDto => {
    const repeatInfo = values.timeMode?.recurrenceInfo
    const timeZone = values.timeMode?.timeZone

    const repeatInfoData = recurrenceInfoToDto(repeatInfo, timeZone)

    // 外部参会人 -- 手动输入
    const inputExternalAttendees = values.userIds?.filter(
      (i) => typeof i === 'string'
    )

    // 外部参会人 -- 选择id
    const selectExternalAttendees = (
      values.userIds?.filter((i) => typeof i === 'number' && i < 0) || []
    ).map((i) => Math.abs(i as number))

    // 内部参会人
    const internalAttendees = values.userIds?.filter(
      (i) => typeof i === 'number' && i > 0
    )

    // 自定义字段数据组装
    const customFields: ReservationCustomFieldValue[] = []
    const { customFieldsValue } = values
    for (const key in customFieldsValue) {
      if (key.includes('__customField__') && customFieldsValue[key]) {
        const resourceId = key.split('__customField__')[0]
        const fieldId = key.split('__customField__')[1]
        customFields.push({
          custom_field: {
            label: 'label',
            value: customFieldsValue[key],
            custom_field_id: Number(fieldId),
          },
          resource_id: Number(resourceId),
        })
      }
    }

    return {
      dryrun,
      action: recurringEditType,
      host_id: values.host?.hostId,
      is_private: values.host?.isPrivate,
      online_meeting_info: values.cloudVideoProviders?.joinUrl
        ? {
            join_url: values.cloudVideoProviders?.joinUrl,
          }
        : undefined,
      notes: values.notes,
      reminder_before: values.reminderBefore,
      resource_ids: resourceIdsState as number[],
      subject: values.subject,
      user_ids: internalAttendees?.length
        ? (internalAttendees as number[])
        : undefined,
      external_attendees: inputExternalAttendees?.length
        ? (inputExternalAttendees as string[])
        : undefined,
      user_book_ids: selectExternalAttendees?.length
        ? (selectExternalAttendees as number[])
        : undefined,
      start_at: values.timeMode.startAt!.format(),
      end_at: values.timeMode.endAt!.format(),
      location_id: currentLocationId!,
      time_mode: values.timeMode.timeMode,
      tzid: values.timeMode.timeZone,
      services: (values?.services || []).map((i) => {
        const serviceItems = i.serviceItems.map((j) => {
          return {
            quantity: j.quantity,
            service_item_id: j.serviceItemId,
          }
        })
        return {
          resource_id: i.resourceId,
          service_type_id: i.serviceTypeId,
          notes: i.notes,
          service_items: serviceItems.length > 0 ? serviceItems : undefined,
        }
      }),
      recurring_event: repeatInfoData,
      custom_fields: customFields,
    }
  }

  /**
   * 回填数据
   */
  const fillFormData = (reservationInfo: ReservationShowEntity) => {
    console.log('reservationInfo', reservationInfo)
    const customFieldsValue: FormValueProps['customFieldsValue'] = {}
    if (reservationInfo.custom_fields?.length) {
      reservationInfo.custom_fields.forEach((i) => {
        customFieldsValue[
          `${i.resource_id}__customField__${i.custom_field.custom_field_id}`
        ] = i.custom_field.value
      })
    }

    form.setFieldsValue({
      resourceIds: reservationInfo.resources.map((i) => i.id),
      services: reservationInfo?.service_reservations?.map((service) => {
        return {
          serviceTypeId: service.service_type_id,
          notes: service.notes,
          serviceItems: service.service_item_reservations?.map((item) => {
            return {
              serviceItemId: item.service_item_id,
              quantity: item.quantity,
              total: item.quantity * Number(item.price),
            }
          }),
        }
      }),
      timeMode: {
        startAt: dayjs(reservationInfo.start_at),
        endAt: dayjs(reservationInfo.end_at),
        timeMode: reservationInfo.time_mode,
        timeZone:
          reservationInfo.tzid ||
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        recurrenceInfo: fillRecurrenceInfoByRrule(
          reservationInfo.recurrence_rrule,
          reservationInfo.start_at
        ),
      },
      host: {
        hostId: reservationInfo.host_id,
        isPrivate: reservationInfo.is_private,
      },
      userIds: reservationInfo?.attendees
        ?.filter((i) => i.role !== 'host')
        ?.map((i) => {
          if (i.is_external) {
            return 0 - (i.user_book_id as number)
          } else {
            return i.user_id as number
          }
        }),
      subject: reservationInfo?.subject,
      reminderBefore: reservationInfo.reminder_before || 0,
      cloudVideoProviders: {
        joinUrl: reservationInfo?.online_meeting_info?.join_url,
        // meetingProvider: reservationInfo?.online_meeting_info?.meeting_provider,
      },
      notes: reservationInfo?.notes,
      customFieldsValue,
    })

    setTimeout(() => {
      setInitialed(true)
    }, 0)
  }

  /**
   * 预定时间以及各种时间冲突的校验--调用后端接口校验
   */
  const validateByBackEnd = useCallback(
    debounce({ delay: 800 }, () => {
      const values = form.getFieldsValue(true)
      console.log(
        '>>> validateByBackEnd values',
        values.timeMode.startAt?.format('MM-DD HH:mm')
      )
      if (!values.resourceIds?.length) return

      const data = formatData(values, true)

      // TODO：格式校验是否放到后端去处理更好？
      if (data.external_attendees?.some((i) => !isValidEmail(i))) {
        // 校验外部参会人的邮箱是否合法
        const errors = errorsMessageByBackEnd || []
        setErrorsMessageByBackEnd([
          ...errors,
          {
            key: 'attendee',
            value: tr('common.enterValidEmail'),
          },
        ])
        return
      }

      if (reservationInfo) {
        return validateUpdateData({
          id: reservationInfo.id,
          data: data,
        })
      } else {
        return validateCreateData({
          data: data as CreateResourceReservationDto,
        })
      }
    }),
    [reservationInfo]
  )

  const getFieldValidateMessages = (keys: string[]) => {
    const fieldErrors = errorsMessageByBackEnd?.filter((i) =>
      keys.includes(i.key)
    )

    if (fieldErrors?.length) {
      return fieldErrors.map((i) => i.value).join(';')
    }
  }

  function onOk() {
    form
      .validateFields()
      .then((values) => {
        console.log(123, values)

        if (isValidating) return

        const data = formatData(values)
        if (reservationInfo?.id) {
          updateResourceReservation({
            id: reservationInfo.id,
            data,
          })
        } else {
          createResourceReservation({
            data: data as CreateResourceReservationDto,
          })
        }
      })
      .catch((err) => {
        console.log('表单校验错误', err)
      })
  }

  const isSubmitting = useMemo(() => {
    return creating || updating
  }, [creating, updating])

  const isValidating = useMemo(() => {
    return isCreateValidating || isUpdateValidating
  }, [isCreateValidating, isUpdateValidating])

  const resourcesList = resourcesListData?.result as ResourceEntity[]

  const selectedResourceList = useMemo(() => {
    return (resourceIdsState || [])
      .map((id) => {
        return (resourcesList || []).find(
          (i: ResourceEntity) => i.id === id
        ) as ResourceEntity
      })
      .filter(Boolean)
  }, [resourceIdsState, resourcesList])

  const submitButtonDisabled = useMemo(() => {
    return showAlert || isValidating || Boolean(errorsMessageByBackEnd?.length)
  }, [showAlert, isValidating, errorsMessageByBackEnd])

  useEffect(() => {
    setResourceIdsState(resourceIds || [])
  }, [resourceIds])

  useEffect(() => {
    // 新增时，修改loading时机
    if (!reservationData && !isGetResourceDataLoading) {
      setInitialed(true)
    }
  }, [isGetResourceDataLoading])

  /**
   * 编辑时，判断是否是集合预定，集合预定需要重新获取api的数据来回填
   */
  useEffect(() => {
    if (groupEditType === 'groupMaster') {
      getGroupReservationData()
    } else if (reservationData) {
      setReservationInfo(reservationData)
      setResourceIdsState(reservationData.resources.map((i) => i.id))
    }
  }, [reservationData])

  /**
   * 获取集合预定主预定数据之后，重新设置state
   */
  useEffect(() => {
    if (groupReservationData) {
      setReservationInfo(groupReservationData)
      setResourceIdsState(groupReservationData.resources.map((i) => i.id))
    }
  }, [groupReservationData])

  /**
   * 回填表单数据
   */
  useEffect(() => {
    if (reservationInfo) {
      fillFormData(reservationInfo)
    }
  }, [reservationInfo])

  useEffect(() => {
    if (selectedResourceList?.length === 1) {
      // 目前时间的默认选择放到组件里面做了
      // setDataWhenOnlyOneResource()
    }
    console.log('selectedResourceList---', selectedResourceList)
  }, [selectedResourceList])

  return (
    <KBModal
      title={tr('reservation.reservedResource')}
      width={664}
      modal={modal}
      okButtonProps={{
        loading: isSubmitting,
        disabled: submitButtonDisabled,
      }}
      onOk={onOk}
    >
      {
        <KBSkeleton loading={!initialed}>
          <KBForm
            form={form}
            labelCol={{ flex: '40px' }}
            labelAlign="left"
            colon={false}
            scrollToFirstError
            initialValues={{
              resourceIds: resourceIds,
              timeMode: {
                startAt: realStartAt, // 已经归整了
                endAt: realEndAt, // 已经归整了
                timeMode: timeMode,
                timeZone: timeZone || dayjs.tz.guess(),
              },
              host: {
                hostId: currentUserId,
                isPrivate: false,
              },
            }}
            onValuesChange={onFormFieldsChange}
          >
            {/* 顶部提醒 */}
            {(showAlert || Boolean(errorsMessageByBackEnd?.length)) && (
              <FormTotalAlert />
            )}

            {/* 选择资源 */}
            <ResourceSelect
              errorsMessages={getFieldValidateMessages(['resource'])}
              resourcesList={resourcesList}
              selectedResourceList={selectedResourceList}
              reservationInfo={reservationInfo}
              formInstance={form}
              isSingleResource={isSingleResource}
              groupEditType={groupEditType}
            />

            {/* 选择服务 */}
            <ServiceSelect
              errorsMessages={getFieldValidateMessages(['service'])}
              resourcesList={selectedResourceList}
              reservationInfo={reservationInfo}
              groupEditType={groupEditType}
            />

            {/* 选择时间模式 */}
            <TimeSlotSelect
              errorsMessages={getFieldValidateMessages(['time'])}
              resourcesList={selectedResourceList}
              recurringEditType={recurringEditType}
              reservationInfo={reservationInfo}
              groupEditType={groupEditType}
            />

            <KBDivider />

            {/* 主持人和私密预定 */}
            <HostSelect
              errorsMessages={getFieldValidateMessages(['host', 'private'])}
              resourcesList={selectedResourceList}
              reservationInfo={reservationInfo}
              groupEditType={groupEditType}
            />

            {/* 参会人 */}
            <AttendeesSelect
              errorsMessages={getFieldValidateMessages(['attendee'])}
              resourcesList={selectedResourceList}
              reservationInfo={reservationInfo}
              groupEditType={groupEditType}
            />

            {/* 主题 */}
            <SubjectInput
              errorsMessages={getFieldValidateMessages(['subject'])}
              resourcesList={selectedResourceList}
              reservationInfo={reservationInfo}
              groupEditType={groupEditType}
            />

            {/* 预定提醒 */}
            <ReminderSelect
              errorsMessages={getFieldValidateMessages(['remindBefore'])}
              resourcesList={selectedResourceList}
              reservationInfo={reservationInfo}
              groupEditType={groupEditType}
            />

            {/* 云会议 */}
            <CloudVideoSelect
              errorsMessages={getFieldValidateMessages(['onlineMeeting'])}
              resourcesList={selectedResourceList}
              reservationInfo={reservationInfo}
              groupEditType={groupEditType}
            />

            {/* 备注 */}
            <NoteInput
              errorsMessages={getFieldValidateMessages(['note'])}
              resourcesList={selectedResourceList}
              reservationInfo={reservationInfo}
              groupEditType={groupEditType}
            />

            {/* 自定义字段 */}
            <CustomFields
              resourcesList={selectedResourceList}
              reservationInfo={reservationInfo}
              groupEditType={groupEditType}
            />
          </KBForm>
        </KBSkeleton>
      }
    </KBModal>
  )
}

export const KBResourceReservationFormModal = NiceModal.create(
  KBResourceReservationForm
)
