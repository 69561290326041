import dayjs, { Dayjs, findClosestTimeWithStep } from '@wox/dayjs'

import {
  CreateResourceReservationDtoTimeMode,
  ReservationShowEntity,
  ResourceEntity,
  TReservationReserveRule,
  UpdateResourceReservationDtoAction,
} from '@/api'
import {
  KBButton,
  KBDatePicker,
  KBDropdown,
  KBFlex,
  KBForm,
  KBIcon,
  KBText,
} from '@/components/atoms'
import {
  KBTimeRangePicker,
  RangePickerMode,
} from '@/components/atoms/KBTimeRangePicker'
import { GroupEditType } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'

import { getRecurrenceDescription } from '@/components/organisms/KBRecurrenceEditModal/constants'
import KBRecurrenceDropdown from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceDropdown'
import KBRecurrenceEditModal, {
  RecurrenceInfo,
} from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceEditModal'
import KBTimezoneSelect from '@/components/organisms/KBTimezoneSelect/KBTimezoneSelect'
import { ReservationTimeModeEnum } from '@/enums/modelEnums'

export interface TimeSlotItem {
  startAt?: Dayjs
  endAt?: Dayjs
  timeMode: CreateResourceReservationDtoTimeMode
  timeZone?: string
  recurrenceInfo?: RecurrenceInfo
}

export interface TimeSlotSelectProps {
  recurringEditType?: UpdateResourceReservationDtoAction
  value?: TimeSlotItem
  groupEditType?: GroupEditType
  onChange?: (data?: TimeSlotItem) => void
  reservationInfo?: ReservationShowEntity
  resourcesList: ResourceEntity[]
  showOther?: boolean
}

export const TimeSlots: React.FC<TimeSlotSelectProps> = (props) => {
  const startAt = props.value?.startAt || dayjs()
  const step = 60
  const {
    value = {
      startAt: findClosestTimeWithStep(startAt, step),
      endAt: startAt.add(step, 'minute'),
      timeMode: ReservationTimeModeEnum.free,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    groupEditType,
    recurringEditType,
    reservationInfo,
    resourcesList,
    onChange,
    showOther = true,
  } = props

  const [minTimeStep, setMinTimeStep] = useState<number>(60)

  const [startTimeBeforeFullDay, setStartTimeBeforeFullDay] = useState<
    Dayjs | undefined
  >()

  const TimeModesDescription = {
    [ReservationTimeModeEnum.free]: tr('reservation.timeByFree'),
    [ReservationTimeModeEnum.fullDay]: tr('reservation.timeByFullDay'),
    [ReservationTimeModeEnum.crossDay]: tr('reservation.timeByCrossDay'),
    [ReservationTimeModeEnum.timeslot]: tr('reservation.timeByTimeslot'),
  }

  const allTimeModes = [
    {
      key: ReservationTimeModeEnum.free,
      label: (
        <KBText>{TimeModesDescription[ReservationTimeModeEnum.free]} </KBText>
      ),
    },
    {
      key: ReservationTimeModeEnum.fullDay,
      label: (
        <KBText>
          {TimeModesDescription[ReservationTimeModeEnum.fullDay]}{' '}
        </KBText>
      ),
    },
    {
      key: ReservationTimeModeEnum.crossDay,
      label: (
        <KBText>
          {TimeModesDescription[ReservationTimeModeEnum.crossDay]}{' '}
        </KBText>
      ),
    },
    // {
    //   key: ReservationTimeModeEnum.timeslot,
    //   label: (
    //     <KBText>
    //       {TimeModesDescription[ReservationTimeModeEnum.timeslot]}{' '}
    //     </KBText>
    //   ),
    // },
  ]

  const [timeModeOptions, setTimeModeOptions] = useState(allTimeModes)

  const onTimeModeClick = (key: string) => {
    // 根据时间模式修改上面的时间选择器

    if (
      value.timeMode === ReservationTimeModeEnum.fullDay &&
      startTimeBeforeFullDay
    ) {
      value.startAt = startTimeBeforeFullDay
    }

    if (key === ReservationTimeModeEnum.fullDay) {
      // 切换全天后，保存之前选择的开始时间 - 因为全天会把开始时间初始成0点
      setStartTimeBeforeFullDay(value.startAt)
      onChange?.({
        ...value,
        timeMode: key,
        startAt: (value.startAt || dayjs()).startOf('day'),
        endAt: dayjs(value.endAt || dayjs()).endOf('day'),
      })
    } else if (key === ReservationTimeModeEnum.crossDay) {
      onChange?.({
        ...value,
        timeMode: key,
        endAt: dayjs(value.startAt).add(24, 'hours'),
      })
    } else if (key === ReservationTimeModeEnum.timeslot) {
      // FIXME:
      alert('开发中')
      // FIXME批量预定不允许按场次预定
      onChange?.({
        ...value,
        timeMode: key,
        // endAt: dayjs(value.startAt).add(24, 'hours'),
      })
    } else {
      // 当前时间往后推最小预定时长
      const startAt = findClosestTimeWithStep(
        value.startAt || dayjs(),
        minTimeStep
      )
      const endAt = startAt.add(minTimeStep, 'minute')

      onChange?.({
        ...value,
        timeMode: key as CreateResourceReservationDtoTimeMode,
        startAt,
        endAt,
      })
    }
  }

  const timeZoneChange = (val: string) => {
    onChange?.({ ...value, timeZone: val })
  }

  const disabled = useMemo(() => {
    if (!reservationInfo) return false
    const { is_group } = reservationInfo
    return groupEditType === 'current'
  }, [reservationInfo])

  const renderTimePicker = () => {
    const types = {
      [ReservationTimeModeEnum.free]: RangePickerMode.DateTimePicker,
      [ReservationTimeModeEnum.fullDay]: RangePickerMode.DatePicker,
      [ReservationTimeModeEnum.crossDay]: RangePickerMode.CrossDatePicker,
      [ReservationTimeModeEnum.timeslot]: RangePickerMode.TimeSegmentPicker,
    }

    // 按天预定
    if (false) {
      return (
        <KBDatePicker
          multiple
          maxTagCount={'responsive'}
          className=" tw-w-full"
          disabledDate={(date) => date.isBefore(dayjs().startOf('day'))}
        />
      )
    }

    return (
      <KBTimeRangePicker
        value={[value.startAt, value.endAt]}
        mode={types[value.timeMode]}
        disabled={disabled}
        disabledDate={(date) => date.isBefore(dayjs().startOf('day'))}
        onChange={(start, end) => {
          onChange?.({
            ...value,
            startAt: start,
            endAt: end,
          })
        }}
      />
    )
  }

  const reSelectTimeMode = () => {
    if (resourcesList.length === 1) {
      const [resource] = resourcesList
      const rule = resource.reservation_reserve_rule
        .rules as TReservationReserveRule
      const {
        allow_reserve_by_across_day,
        allow_reserve_by_full_day,
        allow_reserve_by_timeslot,
        allow_free_time_mode_reserve,
      } = rule
      const result = []

      if (resource.resource_type.book_by_day_only) {
        // 按天预定的资源类型
        result.push(allTimeModes[1])
      } else {
        // 非按天预定得资源类型
        if (allow_free_time_mode_reserve) {
          result.push(allTimeModes[0])
        }
        if (allow_reserve_by_full_day) {
          result.push(allTimeModes[1])
        }
        if (allow_reserve_by_across_day) {
          result.push(allTimeModes[2])
        }
      }

      // if(allow_reserve_by_timeslot) {
      //   result.push(allTimeModes[3])
      // }

      // if (resource.resource_type.book_by_day_only) {
      //   // 只能全日预定的资源自动定位
      //   onTimeModeClick(ReservationTimeModeEnum.fullDay)
      // } else
      if (value?.timeMode && result.every((i) => i.key !== value?.timeMode)) {
        // 如果修改后之前的选项没了，那么自定义到自由预定 -- 初始化时，默认为自由预定，如果资源不能自由预定，这里会onChange到可选的时间模式
        onTimeModeClick(result[0]?.key || ReservationTimeModeEnum.free)
      }
      setTimeModeOptions(result)
    } else {
      setTimeModeOptions([...allTimeModes])
    }
  }

  useEffect(() => {
    if (resourcesList.length === 1) {
      const [resource] = resourcesList
      setMinTimeStep(
        (resource.reservation_reserve_rule.rules as TReservationReserveRule)
          ?.reserve_duration_limit_rule?.min_duration || 60
      )
    }

    // 隐藏不能选择的时间模式
    reSelectTimeMode()
  }, [resourcesList])

  return (
    <>
      <KBFlex align="start" vertical gap={6}>
        <KBFlex vertical gap={8} className="tw-w-full">
          {renderTimePicker()}
          <KBFlex gap={8}>
            <KBDropdown
              menu={{
                items: timeModeOptions,
                onClick: (e) => onTimeModeClick(e.key),
              }}
              placement="bottom"
              trigger={['click']}
              disabled={disabled}
            >
              <KBButton
                icon={<KBIcon name="calendar-clock" />}
                type="text"
                className=" tw-bg-[var(--wox-color-fill-tertiary)]"
              >
                {
                  TimeModesDescription[
                    value?.timeMode as ReservationTimeModeEnum
                  ]
                }
              </KBButton>
            </KBDropdown>
            {showOther && (
              <>
                {' '}
                <KBRecurrenceDropdown
                  value={value.recurrenceInfo}
                  disabled={disabled || recurringEditType === 'current'}
                  buttonProps={{
                    type: 'text',
                  }}
                  className=" tw-bg-[var(--wox-color-fill-tertiary)]"
                  onChange={(info) => {
                    onChange?.({ ...value, recurrenceInfo: info })
                  }}
                />
                <KBTimezoneSelect
                  className="tw-w-[191px]"
                  value={value.timeZone}
                  onChange={(e) => timeZoneChange(e.value)}
                  disabled={disabled}
                  variant="filled"
                />
              </>
            )}
          </KBFlex>
          {value.recurrenceInfo && (
            <KBText className=" tw-text-gray-500">
              {getRecurrenceDescription(value.recurrenceInfo)}
            </KBText>
          )}
        </KBFlex>
      </KBFlex>
      <KBRecurrenceEditModal id="KBRecurrenceEditModal" />
    </>
  )
}

/**
 * 时间组件
 */
const TimeSlotSelect: React.FC<
  TimeSlotSelectProps & {
    errorsMessages?: string
  }
> = (props) => {
  const { errorsMessages } = props

  return (
    <KBForm.Item
      name="timeMode"
      label={<KBIcon size={24} name="clock" />}
      dependencies={['resourceIds']}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <TimeSlots {...props} />
    </KBForm.Item>
  )
}

export default TimeSlotSelect
