import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { KBInput, KBModal } from '@/components/atoms'

interface AddServiceNoteProps {
  serviceTypeId?: number
  resourceId?: number
  value?: string
  onConfirm?: (
    value: string,
    resourceId?: number,
    serviceTypeId?: number
  ) => void
}
function AddServiceNote(props: AddServiceNoteProps) {
  const { resourceId, serviceTypeId, value = '', onConfirm } = props
  const modal = useModal()
  const noteRef = useRef<string>('')

  const _onConfirm = () => {
    modal.hide()
    onConfirm?.(noteRef.current, resourceId, serviceTypeId)
  }

  return (
    <KBModal modal={modal} title={tr('common.addRemarks')} onOk={_onConfirm}>
      <KBInput.TextArea
        placeholder={tr('common.remarksPlaceholder')}
        defaultValue={value}
        rows={4}
        onChange={(event) => {
          noteRef.current = event.target.value
        }}
      />
    </KBModal>
  )
}

const AddServiceNoteModal = NiceModal.create(AddServiceNote)

export default AddServiceNoteModal
