import NiceModal from '@ebay/nice-modal-react'
import { wrapUseRoutes } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { useTitle } from 'ahooks'
import { App as AntdApp } from 'antd'
import { Suspense } from 'react'

import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import routes from '@/routes'
import { useReactQueryClient } from '@/utils/react-query-client'

const KBNoticeBillingModal = lazy(
  () =>
    import('@/components/organisms/KBNoticeBillingModal/KBNoticeBillingModal')
)
const NewVersionModal = lazy(
  () => import('@/components/layouts/components/NewVersionModal')
)
const KBMessageGlobal = lazy(() => import('@/components/atoms/KBMessageGlobal'))
const KBMapLocationModal = lazy(
  () => import('@/components/organisms/KBMapLocationModal/KBMapLocationModal')
)

import { themeAtom } from '@/store'
import { darkTheme } from '@/themes/antd/darkTheme'
import { lightTheme } from '@/themes/antd/lightTheme'
import { AnimatePresence } from 'framer-motion'
import { useAtom } from 'jotai'
import { LocaleProvider } from './contexts/app_context'

const useSentryRoutes = wrapUseRoutes(useRoutes)
function RouteContent() {
  return <>{useSentryRoutes(routes)}</>
}

const App = () => {
  const queryClientRef = useReactQueryClient()
  const [theme] = useAtom(themeAtom)

  useTitle(import.meta.env.VITE_BRAND)
  return (
    <AntdApp>
      <Suspense fallback={<KBSuspenseLoading />}>
        <QueryClientProvider client={queryClientRef.current}>
          <LocaleProvider
            theme={{
              cssVar: {
                prefix: 'wox',
              },
              hashed: false,
              ...(theme === 'dark' ? darkTheme : lightTheme),
            }}
          >
            <NiceModal.Provider>
              {/* 费用中心权限的提示弹框 */}
              <Suspense fallback={null}>
                <KBNoticeBillingModal id="KBNoticeBillingModal" />
                <NewVersionModal />
                <KBMessageGlobal />
                <KBMapLocationModal id="KBMapLocationModal" />
              </Suspense>
              <AnimatePresence>
                <RouteContent />
              </AnimatePresence>
            </NiceModal.Provider>
            {/* {isDevEnv && <ReactQueryDevtools initialIsOpen={false} />} */}
          </LocaleProvider>
        </QueryClientProvider>
      </Suspense>
    </AntdApp>
  )
}

export default App
