// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { VisitorSignOutMutationResponse, VisitorSignOutQueryParams, VisitorSignOut401, VisitorSignOut406, VisitorSignOut409 } from "../models/VisitorSignOut";
import type { UseMutationOptions } from "@tanstack/react-query";

 type VisitorSignOutClient = typeof client<VisitorSignOutMutationResponse, VisitorSignOut401 | VisitorSignOut406 | VisitorSignOut409, never>;
type VisitorSignOut = {
    data: VisitorSignOutMutationResponse;
    error: VisitorSignOut401 | VisitorSignOut406 | VisitorSignOut409;
    request: never;
    pathParams: never;
    queryParams: VisitorSignOutQueryParams;
    headerParams: never;
    response: VisitorSignOutMutationResponse;
    client: {
        parameters: Partial<Parameters<VisitorSignOutClient>[0]>;
        return: Awaited<ReturnType<VisitorSignOutClient>>;
    };
};
/**
 * @summary 签退访客
 * @link /api/v5/visitors/sign-out
 */
export function useVisitorSignOut(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<VisitorSignOut["response"], VisitorSignOut["error"], {
        params: VisitorSignOut["queryParams"];
        data?: VisitorSignOut["request"];
    }>;
    client?: VisitorSignOut["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ params }) => {
            const res = await client<VisitorSignOut["data"], VisitorSignOut["error"], VisitorSignOut["request"]>({
                method: "patch",
                url: `/api/v5/visitors/sign-out`,
                params,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "visitors", "sign-out"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}