import React from 'react'

import { ReservationShowEntity, ResourceEntity } from '@/api'
import { KBDivider, KBFlex, KBText } from '@/components/atoms'
import KBCustomFieldFormItem from '@/components/molecules/KBCustomFieldFormItem/KBCustomFieldFormItem'
import { GroupEditType } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'

interface CustomFieldsProps {
  resourcesList: ResourceEntity[]
  groupEditType?: GroupEditType
  reservationInfo?: ReservationShowEntity
}
/**
 * 自定义字段组件
 */
const CustomFields: React.FC<CustomFieldsProps> = (props) => {
  const { resourcesList, groupEditType, reservationInfo } = props

  const resourcesWithCustomFields = useMemo(() => {
    return resourcesList
      .map((i) => {
        return {
          ...i,
          custom_fields: i?.reservation_form_rule?.custom_fields,
        }
      })
      .filter(Boolean)
  }, [resourcesList])

  const showCustomFields = useMemo(() => {
    return resourcesList.some((i) =>
      Boolean(i?.reservation_form_rule?.custom_fields?.length)
    )
  }, [resourcesList])

  const disabled = useMemo(() => {
    if (!reservationInfo) return false

    const { is_group } = reservationInfo

    return groupEditType === 'current'
  }, [reservationInfo])

  return showCustomFields ? (
    <>
      <KBDivider className=" tw-my-8" />
      <KBFlex className="tw-mb-6">
        <KBText className=" tw-text-black" level={1} weight="bold">
          {tr('common.customFields')}
        </KBText>
      </KBFlex>
      {resourcesWithCustomFields
        .filter((i) => Boolean(i.custom_fields?.length))
        .map((resource) => {
          return (
            <>
              {resource.custom_fields?.map((field) => {
                return (
                  <KBCustomFieldFormItem
                    key={field.id}
                    formItemLayout="vertical"
                    customField={field}
                    labelCol={{ span: 24 }}
                    disabled={disabled}
                    customLabel={
                      <>
                        {field.label}
                        <KBText className="tw-text-gray-500">
                          ({resource.name})
                        </KBText>
                      </>
                    }
                    label={field.label}
                    name={`customFieldsValue.${resource.id}__customField__${field.id}`.split(
                      '.'
                    )}
                  />
                )
              })}
            </>
          )
        })}
    </>
  ) : null
}

export default CustomFields
