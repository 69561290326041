import {
  ReservationShowEntity,
  ResourceEntity,
  SimpleResourceWithPolicyEntity,
  useResourceReservationAdminUpdate,
  useResourceReservationUpdate,
} from '@/api'
import { KBFlex, KBModal, KBText } from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import useUserLocationOrDefault from '@/hooks/useUserLocationOrDefault'
import { isUserClient } from '@/utils/userEnvironment'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import dayjs, { Dayjs, formatDate, formatTime } from '@wox/dayjs'

interface ExtendMeetingModalProps {
  reservation?: ReservationShowEntity
  resource?: SimpleResourceWithPolicyEntity | Partial<ResourceEntity>
}

function ExtendMeetingModal(props: ExtendMeetingModalProps) {
  const { reservation, resource } = props
  const { start_at, end_at, id } = reservation!

  const modal = useModal()
  const defaultLocation = useUserLocationOrDefault()

  const [selectTime, setSelectTime] = useState<number | undefined>()

  const editApi = isUserClient()
    ? useResourceReservationUpdate
    : useResourceReservationAdminUpdate
  const { mutate: updateResourceReservation, isPending: updating } = editApi({})

  const hoverClass = ` hover:tw-bg-[var(--wox-color-primary)] hover:tw-text-white`
  const selectClass = `tw-bg-[var(--wox-color-primary)] tw-text-white`

  const interval = defaultLocation?.time_interval || 30

  const isByDay = !!resource?.resource_type?.book_by_day_only

  const time1 = isByDay
    ? dayjs(end_at).add(1, 'day')
    : dayjs(end_at).add(interval, 'minute')
  const time2 = isByDay
    ? dayjs(end_at).add(2, 'day')
    : dayjs(end_at).add(interval * 2, 'minute')
  const time3 = isByDay
    ? dayjs(end_at).add(3, 'day')
    : dayjs(end_at).add(interval * 3, 'minute')
  const selectTimeArray = [time1, time2, time3]

  const delayTime = () => {
    if (selectTime) {
      updateResourceReservation(
        {
          id,
          data: {
            start_at,
            end_at: selectTimeArray[selectTime - 1].format(),
            resource_ids: [resource?.id!],
            update_mode: 'extend',
          },
        },
        {
          onSuccess: () => {
            modal.hide()
            kbMessage.success(tr('common.delaySuccess'))
          },
        }
      )
    }
  }

  const diffTime = (starTime: Dayjs, endTime: Dayjs) => {
    const totalMinutes = endTime.diff(starTime, 'minute')
    if (totalMinutes < 60) {
      return `${totalMinutes} ${tr('common.minute')}`
    }
    if (totalMinutes === 60) {
      return `1 ${tr('common.hour')}`
    }
    const hour = (totalMinutes / 60).toFixed(2).split('.')[0]
    const minute = totalMinutes % 60

    return `${hour} ${tr('common.hour')} ${minute} ${tr('common.minute')}`
  }

  return (
    <KBModal
      modal={modal}
      title={tr('common.extendReservation')}
      width={384}
      onOk={delayTime}
    >
      <KBFlex vertical gap={8} className="tw-w-full">
        {selectTimeArray.map((time, index) => {
          return (
            <KBText
              className={`tw-block tw-cursor-pointer tw-rounded-md tw-bg-[var(--wox-color-fill-quaternary)] tw-px-4 tw-py-2.5 ${selectTime === index + 1 ? selectClass : hoverClass}`}
              key={index}
              onClick={() => setSelectTime(index + 1)}
            >
              {tr('common.delayTo')}{' '}
              {isByDay ? formatDate(time) : formatTime(time)} ({' '}
              {isByDay
                ? tr('common.numberDay', { day: index + 1 })
                : diffTime(dayjs(end_at), time)}{' '}
              )
            </KBText>
          )
        })}
      </KBFlex>
    </KBModal>
  )
}

export default NiceModal.create(ExtendMeetingModal)
