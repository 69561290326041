import { FormInstance } from 'antd'
import React from 'react'

import { AuthUserEntity, useAuthResetPassword } from '@/api'
import {
  KBButton,
  KBFlex,
  KBForm,
  KBInput,
  KBTypography,
} from '@/components/atoms'
const { Title, Text, Link } = KBTypography

import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { tr } from '@/utils/localization/tr'
import { Rule } from 'antd/es/form'
import WoxIconLogo from '~icons/wox-icons/woxLogo.svg'

interface ResetPasswordProps {
  form: FormInstance
  authUser: AuthUserEntity
}

const ResetPassword: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // get hash from url query string token
  const hash = location.search.split('token=')[1]
  const [form] = KBForm.useForm()

  const { mutate: resetPassword, isPending } = useAuthResetPassword()

  const validateConfirmPassword = (rule: Rule, value: string) => {
    if (!value || value === form.getFieldValue('password')) {
      return Promise.resolve()
    }
    return Promise.reject(tr('login.passwordNotMatch'))
  }

  const onSubmit = () => {
    form.validateFields().then((values) => {
      if (values.password !== values.confirmPassword) {
        kbMessage.error(tr('login.passwordNotMatch'))
        return
      }
      // 提交表单
      resetPassword(
        {
          data: {
            password: values.password,
            hash: hash,
          },
        },
        {
          onSuccess: () => {
            navigate('/reset-password-success')
          },
        }
      )
    })
  }

  return (
    <KBFlex
      vertical
      gap={32}
      justify="center"
      align="center"
      className="tw-mx-auto tw-translate-y-1/2 "
    >
      <KBFlex align="center" gap={24} vertical>
        <WoxIconLogo />
        <Title level={2} className="tw-m-0">
          {tr('login.resetPassword')}
        </Title>
      </KBFlex>
      <KBFlex className=" tw-w-[350px]  tw-rounded-lg tw-border tw-border-solid tw-border-gray-200 tw-p-10 tw-shadow">
        <KBForm form={form} layout="vertical" className="tw-w-full">
          <KBForm.Item
            name="password"
            label={tr('login.password')}
            rules={[
              {
                required: true,
                message: tr('login.passwordDesc'),
              },
            ]}
            className="tw-mb-6"
            extra={tr('login.passwordExtraDesc')}
          >
            <KBInput.Password
              size="large"
              placeholder={tr('login.passwordDesc')}
            />
          </KBForm.Item>
          <KBForm.Item
            name="confirmPassword"
            label={tr('login.confirmPassword')}
            rules={[
              {
                required: true,
                message: tr('login.confirmPasswordDesc'),
              },
              { validator: validateConfirmPassword },
            ]}
          >
            <KBInput.Password
              size="large"
              placeholder={tr('login.confirmPasswordDesc')}
            />
          </KBForm.Item>

          <KBForm.Item className="tw-mb-0 tw-mt-3">
            <KBButton
              type="primary"
              size="large"
              onClick={onSubmit}
              className="tw-w-full"
              loading={isPending}
            >
              {tr('login.save')}
            </KBButton>
          </KBForm.Item>
        </KBForm>
      </KBFlex>
    </KBFlex>
  )
}

export default ResetPassword
