import { KBAlert, KBForm } from '@/components/atoms'

const FormTotalAlert = () => {
  return (
    <KBForm.Item>
      <KBAlert message={tr('reservation.totalAlert')} type="error" />
    </KBForm.Item>
  )
}

export default FormTotalAlert
