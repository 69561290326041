import React from 'react'

import { ReservationShowEntity, ResourceEntity } from '@/api'
import { KBForm, KBIcon } from '@/components/atoms'
import { GroupEditType } from '@/components/molecules/KBResourceReservationForm/KBResourceReservationForm'
import { KBUserSelect } from '@/components/organisms'

interface AttendeesSelectProps {
  value?: number[]
  resourcesList: ResourceEntity[]
  groupEditType?: GroupEditType
  reservationInfo?: ReservationShowEntity
  errorsMessages?: string
  onChange?: (value: (number | string)[]) => void
}
/**
 * 选择参会人组件
 */
const AttendeesSelect: React.FC<AttendeesSelectProps> = (props) => {
  const {
    value,
    onChange,
    resourcesList,
    reservationInfo,
    groupEditType,
    errorsMessages,
  } = props

  /**
  const validateAttendees = (userIds: string[] | number[] | undefined) => {
    const externals: string[] = []
    const internals: number[] = []
    userIds?.forEach((i) => {
      if (typeof i === 'string') {
        externals.push(i)
      } else if (typeof i === 'number') {
        internals.push(i)
      }
    })

    if (externals?.length) {
      // 选择外部参会人校验
      const cannotSelectExternalAttendees = resourcesList.filter(
        (i) =>
          !(i.reservation_form_rule?.rules as TReservationFormRule)
            ?.external_attendees
      )
      if (cannotSelectExternalAttendees?.length) {
        return Promise.reject(
          new Error(
            `${cannotSelectExternalAttendees.map((i) => i.name).join('、')}${tr('[intl]不允许选择外部参会人')}`
          )
        )
      }
    }

    if (internals?.length) {
      // 选择内部参会人校验
      const cannotSelectInternalAttendees = resourcesList.filter(
        (i) =>
          !(i.reservation_form_rule?.rules as TReservationFormRule)
            ?.internal_attendees
      )
      if (cannotSelectInternalAttendees?.length) {
        return Promise.reject(
          new Error(
            `${cannotSelectInternalAttendees.map((i) => i.name).join('、')}${tr('[intl]不允许选择内部参会人')}`
          )
        )
      }
    }

    if (userIds?.length) {
      // 独享资源校验
      const cannotSelectExclusiveResources = resourcesList.filter(
        (i) => i.resource_type?.usage_mode === 'exclusive'
      )
      if (cannotSelectExclusiveResources?.length) {
        return Promise.reject(
          new Error(
            `${cannotSelectExclusiveResources.map((i) => i.name).join('、')}${tr('[intl]为独享资源，不允许选择参会人')}`
          )
        )
      }
    }
    return Promise.resolve()
  }
  */

  const showAttendees = useMemo(() => {
    return (resourcesList || []).some((i) => {
      const rules = i.reservation_reserve_rule?.rules
      const isShareAble = i.resource_type.usage_mode === 'shareable'
      return (
        (rules?.external_attendees || rules?.internal_attendees) && isShareAble
      )
    })
  }, [resourcesList])

  const includes = useMemo(() => {
    const result: ('internal' | 'external')[] = []
    if (
      (resourcesList || []).some(
        (i) => i.reservation_reserve_rule?.rules?.internal_attendees
      )
    ) {
      result.push('internal')
    }
    if (
      (resourcesList || []).some(
        (i) => i.reservation_reserve_rule?.rules?.external_attendees
      )
    ) {
      result.push('external')
    }
    return result
  }, [resourcesList])

  const disabled = useMemo(() => {
    if (!reservationInfo) return false

    const { is_group } = reservationInfo

    return groupEditType === 'current'
  }, [reservationInfo])

  return showAttendees ? (
    <KBForm.Item
      name="userIds"
      label={<KBIcon name="users" size={24} />}
      dependencies={['resourceIds']}
      // rules={[
      //   {
      //     validator: (_, userIds) => validateAttendees(userIds),
      //   },
      // ]}
      validateStatus={Boolean(errorsMessages?.length) ? 'error' : undefined}
      help={errorsMessages}
    >
      <KBUserSelect
        mode="tags"
        // maxTagCount="responsive"
        // maxTagPlaceholder={(omittedValues) => (
        //   <KBTooltip
        //     overlayStyle={{ pointerEvents: 'none' }}
        //     title={omittedValues
        //       .map(({ label }) =>
        //         typeof label === 'string'
        //           ? label
        //           : // eslint-disable-next-line typescriptEslint/no-explicit-any
        //             (label as any)?.props.user.name
        //       )
        //       .join(', ')}
        //   >
        //     <span>+{omittedValues.length}</span>
        //   </KBTooltip>
        // )}
        includes={includes}
        className="tw-w-full"
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </KBForm.Item>
  ) : null
}

export default AttendeesSelect
