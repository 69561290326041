// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { VisitorUpdateOneMutationRequest, VisitorUpdateOneMutationResponse, VisitorUpdateOneQueryParams, VisitorUpdateOne401, VisitorUpdateOne406, VisitorUpdateOne409 } from "../models/VisitorUpdateOne";
import type { UseMutationOptions } from "@tanstack/react-query";

 type VisitorUpdateOneClient = typeof client<VisitorUpdateOneMutationResponse, VisitorUpdateOne401 | VisitorUpdateOne406 | VisitorUpdateOne409, VisitorUpdateOneMutationRequest>;
type VisitorUpdateOne = {
    data: VisitorUpdateOneMutationResponse;
    error: VisitorUpdateOne401 | VisitorUpdateOne406 | VisitorUpdateOne409;
    request: VisitorUpdateOneMutationRequest;
    pathParams: never;
    queryParams: VisitorUpdateOneQueryParams;
    headerParams: never;
    response: VisitorUpdateOneMutationResponse;
    client: {
        parameters: Partial<Parameters<VisitorUpdateOneClient>[0]>;
        return: Awaited<ReturnType<VisitorUpdateOneClient>>;
    };
};
/**
 * @summary 更新唯一的VisitorEntity
 * @link /api/v5/visitors
 */
export function useVisitorUpdateOne(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<VisitorUpdateOne["response"], VisitorUpdateOne["error"], {
        params: VisitorUpdateOne["queryParams"];
        data?: VisitorUpdateOne["request"];
    }>;
    client?: VisitorUpdateOne["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ params, data }) => {
            const res = await client<VisitorUpdateOne["data"], VisitorUpdateOne["error"], VisitorUpdateOne["request"]>({
                method: "patch",
                url: `/api/v5/visitors`,
                params,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "visitors"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}