import React from 'react'

import { VisitorEntityUnifiedStatus } from '@/api'
import { IconName, KBIcon } from '@/components/atoms'
import { VisitorUnifiedStatusEnum } from '@/enums/modelEnums'
import { cn } from '@/utils/tailwind.helper'
import { Tooltip } from 'antd'

interface VisitorStateIconProps {
  visitorState?: VisitorEntityUnifiedStatus
  showTooltip?: boolean
}

const VisitorStateIcons = {
  [VisitorUnifiedStatusEnum.signed_in]: 'check',
  [VisitorUnifiedStatusEnum.signed_out]: 'check',
  [VisitorUnifiedStatusEnum.approving]: 'ellipsis',
  [VisitorUnifiedStatusEnum.cancelled]: 'x',
  [VisitorUnifiedStatusEnum.waiting_for_acceptance]: 'circle',
  [VisitorUnifiedStatusEnum.waiting_for_sign]: 'clock',
}

const VisitorStateIcon: React.FC<VisitorStateIconProps> = ({
  visitorState,
  showTooltip,
}) => {
  const state = visitorState as VisitorUnifiedStatusEnum
  const element = (
    // 外边包一层div 解决闪动问题
    <div>
      <KBIcon
        size={16}
        name={(VisitorStateIcons[state] || 'x') as IconName}
        className={cn(
          'tw-mr-0.5 tw-flex tw-justify-center tw-rounded-[50px] tw-align-middle tw-text-white tw-opacity-80 hover:tw-opacity-100',
          {
            'tw-bg-orange-400':
              state === VisitorUnifiedStatusEnum.approving ||
              state === VisitorUnifiedStatusEnum.waiting_for_sign,
            // 'tw-bg-blue-400': state === VisitorUnifiedStatusEnum.signed_out,
            'tw-bg-green-400': state === VisitorUnifiedStatusEnum.signed_in,
            'tw-bg-gray-500':
              state === VisitorUnifiedStatusEnum.waiting_for_acceptance ||
              VisitorUnifiedStatusEnum.cancelled ||
              state === VisitorUnifiedStatusEnum.signed_out,
          }
        )}
      />
    </div>
  )
  return showTooltip ? (
    <Tooltip title={tr(`state.${state}`)}>{element}</Tooltip>
  ) : (
    element
  )
}

export default VisitorStateIcon
