// @ts-nocheck
import client from "../../utils/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { VisitorAdminVisitorSignInMutationRequest, VisitorAdminVisitorSignInMutationResponse, VisitorAdminVisitorSignInQueryParams, VisitorAdminVisitorSignIn401, VisitorAdminVisitorSignIn406, VisitorAdminVisitorSignIn409 } from "../models/VisitorAdminVisitorSignIn";
import type { UseMutationOptions } from "@tanstack/react-query";

 type VisitorAdminVisitorSignInClient = typeof client<VisitorAdminVisitorSignInMutationResponse, VisitorAdminVisitorSignIn401 | VisitorAdminVisitorSignIn406 | VisitorAdminVisitorSignIn409, VisitorAdminVisitorSignInMutationRequest>;
type VisitorAdminVisitorSignIn = {
    data: VisitorAdminVisitorSignInMutationResponse;
    error: VisitorAdminVisitorSignIn401 | VisitorAdminVisitorSignIn406 | VisitorAdminVisitorSignIn409;
    request: VisitorAdminVisitorSignInMutationRequest;
    pathParams: never;
    queryParams: VisitorAdminVisitorSignInQueryParams;
    headerParams: never;
    response: VisitorAdminVisitorSignInMutationResponse;
    client: {
        parameters: Partial<Parameters<VisitorAdminVisitorSignInClient>[0]>;
        return: Awaited<ReturnType<VisitorAdminVisitorSignInClient>>;
    };
};
/**
 * @summary 签到访客
 * @link /api/v5/admin/visitors/sign-in
 */
export function useVisitorAdminVisitorSignIn(options: {
    invalidationOnSuccess?: boolean;
    mutation?: UseMutationOptions<VisitorAdminVisitorSignIn["response"], VisitorAdminVisitorSignIn["error"], {
        params: VisitorAdminVisitorSignIn["queryParams"];
        data?: VisitorAdminVisitorSignIn["request"];
    }>;
    client?: VisitorAdminVisitorSignIn["client"]["parameters"];
} = {}) {
    const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};
    const queryClient = useQueryClient();
    let invalidationOnSuccessOption = options?.invalidationOnSuccess ?? true;
    return useMutation({
        mutationFn: async ({ params, data }) => {
            const res = await client<VisitorAdminVisitorSignIn["data"], VisitorAdminVisitorSignIn["error"], VisitorAdminVisitorSignIn["request"]>({
                method: "patch",
                url: `/api/v5/admin/visitors/sign-in`,
                params,
                data,
                ...clientOptions
            });
            return res.data;
        },
        ...mutationOptions,
        onSuccess: (...args) => {
            if (invalidationOnSuccessOption) {
                queryClient.invalidateQueries({
                    queryKey: ["api", "v5", "admin", "visitors"]
                });
            }
            if (mutationOptions?.onSuccess)
                mutationOptions.onSuccess(...args);
        },
    });
}