import { useLocationAdminFindById } from '@/api'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { locationAtom } from '@/store'
import { FormatOptions, formatStorage } from '@wox/dayjs'
import { useSetAtom } from 'jotai'
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
export const LocationLayout: React.FC = () => {
  const currentLocationId = useCurrentLocationId()
  const setLocation = useSetAtom(locationAtom)
  const { data: currentLocation, isLoading } = useLocationAdminFindById(
    currentLocationId!
  )

  useEffect(() => {
    setLocation(currentLocation)
    if (currentLocation) {
      const formatOptions: FormatOptions = {
        dateFormat: currentLocation.date_format,
        // timeFormat: location.time_format,
        timeZone: currentLocation.time_zone,
        locale: currentLocation.language,
        // weekdayFormat: location.weekday_format,
        firstDayOfWeek: currentLocation.first_day_of_week,
      }

      formatStorage.set(formatOptions)
    }
  }, [currentLocation])

  return isLoading ? <KBSuspenseLoading /> : <Outlet />
}
