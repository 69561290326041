// @ts-nocheck
import client from "../../utils/axios";
import { useQuery, queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { LocationGetAllLocationsQueryResponse, LocationGetAllLocations401 } from "../models/LocationGetAllLocations";
import type { QueryObserverOptions, UseQueryResult, QueryKey, UseSuspenseQueryOptions, UseSuspenseQueryResult } from "@tanstack/react-query";

 type LocationGetAllLocationsClient = typeof client<LocationGetAllLocationsQueryResponse, LocationGetAllLocations401, never>;
type LocationGetAllLocations = {
    data: LocationGetAllLocationsQueryResponse;
    error: LocationGetAllLocations401;
    request: never;
    pathParams: never;
    queryParams: never;
    headerParams: never;
    response: LocationGetAllLocationsQueryResponse;
    client: {
        parameters: Partial<Parameters<LocationGetAllLocationsClient>[0]>;
        return: Awaited<ReturnType<LocationGetAllLocationsClient>>;
    };
};
export const locationGetAllLocationsQueryKey = () => ["api", "v5", "locations", "all"] as const;
export type LocationGetAllLocationsQueryKey = ReturnType<typeof locationGetAllLocationsQueryKey>;
export function locationGetAllLocationsQueryOptions(options: LocationGetAllLocations["client"]["parameters"] = {}) {
    const queryKey = locationGetAllLocationsQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<LocationGetAllLocations["data"], LocationGetAllLocations["error"]>({
                method: "get",
                url: `/api/v5/locations/all`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Get all locations
 * @link /api/v5/locations/all
 */
export function useLocationGetAllLocations<TData = LocationGetAllLocations["response"], TQueryData = LocationGetAllLocations["response"], TQueryKey extends QueryKey = LocationGetAllLocationsQueryKey>(options: {
    query?: Partial<QueryObserverOptions<LocationGetAllLocations["response"], LocationGetAllLocations["error"], TData, TQueryData, TQueryKey>>;
    client?: LocationGetAllLocations["client"]["parameters"];
} = {}): UseQueryResult<TData, LocationGetAllLocations["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? locationGetAllLocationsQueryKey();
    const query = useQuery({
        ...locationGetAllLocationsQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseQueryResult<TData, LocationGetAllLocations["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}
export const locationGetAllLocationsSuspenseQueryKey = () => ["api", "v5", "locations", "all"] as const;
export type LocationGetAllLocationsSuspenseQueryKey = ReturnType<typeof locationGetAllLocationsSuspenseQueryKey>;
export function locationGetAllLocationsSuspenseQueryOptions(options: LocationGetAllLocations["client"]["parameters"] = {}) {
    const queryKey = locationGetAllLocationsSuspenseQueryKey();
    return queryOptions({
        queryKey,
        queryFn: async () => {
            const res = await client<LocationGetAllLocations["data"], LocationGetAllLocations["error"]>({
                method: "get",
                url: `/api/v5/locations/all`,
                ...options
            });
            return res.data;
        },
    });
}
/**
 * @summary Get all locations
 * @link /api/v5/locations/all
 */
export function useLocationGetAllLocationsSuspense<TData = LocationGetAllLocations["response"], TQueryKey extends QueryKey = LocationGetAllLocationsSuspenseQueryKey>(options: {
    query?: Partial<UseSuspenseQueryOptions<LocationGetAllLocations["response"], LocationGetAllLocations["error"], TData, TQueryKey>>;
    client?: LocationGetAllLocations["client"]["parameters"];
} = {}): UseSuspenseQueryResult<TData, LocationGetAllLocations["error"]> & {
    queryKey: TQueryKey;
} {
    const { query: queryOptions, client: clientOptions = {} } = options ?? {};
    const queryKey = queryOptions?.queryKey ?? locationGetAllLocationsSuspenseQueryKey();
    const query = useSuspenseQuery({
        ...locationGetAllLocationsSuspenseQueryOptions(clientOptions) as unknown as QueryObserverOptions,
        queryKey,
        ...queryOptions as unknown as Omit<QueryObserverOptions, "queryKey">
    }) as UseSuspenseQueryResult<TData, LocationGetAllLocations["error"]> & {
        queryKey: TQueryKey;
    };
    query.queryKey = queryKey as TQueryKey;
    return query;
}