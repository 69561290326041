import React from 'react'

import {
  useVisitorAdminApproveVisitor,
  useVisitorAdminCancel,
  useVisitorAdminVisitorSignIn,
  useVisitorAdminVisitorSignOut,
  useVisitorCancel,
  useVisitorConfigAdminFindByLocationId,
  useVisitorConfigFindByLocationId,
  useVisitorSignIn,
  useVisitorSignOut,
  useVisitorUpdateOne,
  useVisitorVisitorApprovalPassed,
  VisitorEntity,
} from '@/api'
import {
  KBButton,
  KBDropdown,
  KBFlex,
  KBIcon,
  KBTag,
  KBText,
  KBTooltip,
} from '@/components/atoms'
import { kbMessage } from '@/components/atoms/KBMessageGlobal'
import { KBUser } from '@/components/organisms'
import VisitorStateIcon from '@/components/organisms/KBVisitorListItem/VisitorStateIcon'
import {
  VisitorRequestTypeEnum,
  VisitorUnifiedStatusEnum,
} from '@/enums/modelEnums'
import useCurrentLocationId from '@/hooks/useCurrentLocationId'
import { isUserClient } from '@/utils/userEnvironment'
import NiceModal from '@ebay/nice-modal-react'
import { formatDateMonthDay, formatTime } from '@wox/dayjs'
import { MenuProps } from 'antd'

interface KBVisitorListItemProps {
  visitor: VisitorEntity
  refetch?: () => void
  onEditVisitor?: (visitor: VisitorEntity, isInviteAgain?: boolean) => void
  onRejectVisitor?: (visitor: VisitorEntity) => void
  /**
   * @description 是否只读
   */
  readonly?: boolean
}

const KBVisitorListItem: React.FC<KBVisitorListItemProps> = ({
  visitor,
  refetch,
  onEditVisitor,
  onRejectVisitor,
  readonly = false,
}) => {
  const navigate = useNavigate()
  const currentLocationId = useCurrentLocationId()
  const [isAnimating, setIsAnimating] = useState(false)
  const { mutate: updateVisitor } = useVisitorUpdateOne({
    invalidationOnSuccess: false,
  })

  const signInApi = isUserClient()
    ? useVisitorSignIn
    : useVisitorAdminVisitorSignIn

  const { mutate: signInVisitor } = signInApi()

  const { mutate: signOutVisitor } = isUserClient()
    ? useVisitorSignOut()
    : useVisitorAdminVisitorSignOut()

  const { mutate: approveVisitor } = isUserClient()
    ? useVisitorVisitorApprovalPassed()
    : useVisitorAdminApproveVisitor()

  const cancelApi = isUserClient() ? useVisitorCancel : useVisitorAdminCancel

  const { mutate: cancelVisitor } = cancelApi({
    invalidationOnSuccess: false,
  })

  const visitorConfigApi = isUserClient()
    ? useVisitorConfigFindByLocationId
    : useVisitorConfigAdminFindByLocationId
  const { data: visitorConfig } = visitorConfigApi(
    {
      location_id: currentLocationId!,
    },
    {
      query: {
        enabled: !readonly,
      },
    }
  )

  const handleCancel = () => {
    cancelVisitor(
      {
        params: {
          id: visitor?.id,
        },
      },
      {
        onSuccess: () => {
          kbMessage.success(tr('common.cancelSuccess'))
          refetch?.()
        },
      }
    )
  }

  const handleApprove = () => {
    approveVisitor(
      {
        params: {
          id: visitor?.id,
        },
      },
      {
        onSuccess: () => {
          kbMessage.success(tr('common.approveSuccess'))
          refetch?.()
        },
      }
    )
  }

  const recurrentTypes = [
    {
      key: 'current',
      label: tr('reservation.currentReservation'),
    },
    {
      key: 'current_to_end',
      label: tr('reservation.currentAndSubsequentReservations'),
    },
    {
      key: 'serial',
      label: tr('reservation.allReservations'),
    },
  ]

  const actionItems: MenuProps['items'] = []

  if (visitor.unified_status !== VisitorUnifiedStatusEnum.cancelled) {
    actionItems.push({
      key: 'edit',
      label: (
        <KBFlex gap={8} align="center">
          <KBIcon name="square-pen" />
          {visitor?.is_recurrent ? (
            <KBDropdown
              menu={{
                items: recurrentTypes,
                onClick: (e) => {
                  // FIXME:周期性访客编辑 需绑接口
                  onEditVisitor && onEditVisitor(visitor)
                  console.log(e.key)
                },
              }}
            >
              <KBButton className="tw-h-fit tw-p-0" type="text">
                {tr('common.edit')}
              </KBButton>
            </KBDropdown>
          ) : (
            <KBButton
              className="tw-h-fit tw-p-0"
              type="text"
              onClick={() => onEditVisitor && onEditVisitor(visitor)}
            >
              {tr('common.edit')}
            </KBButton>
          )}
        </KBFlex>
      ),
    })
  }

  const isUserClientApprovalAndReject =
    isUserClient() &&
    ((visitorConfig?.approval?.approval_by_host_user &&
      visitor?.request_type === VisitorRequestTypeEnum.invite) ||
      (visitorConfig?.approval?.apply_approval_by_host_user &&
        visitor?.request_type === VisitorRequestTypeEnum.request))

  const canInviteAgain =
    visitor?.unified_status === VisitorUnifiedStatusEnum.waiting_for_sign ||
    visitor?.unified_status === VisitorUnifiedStatusEnum.signed_in ||
    visitor?.unified_status === VisitorUnifiedStatusEnum.signed_out ||
    visitor?.unified_status === VisitorUnifiedStatusEnum.cancelled

  if (canInviteAgain) {
    actionItems.push({
      key: 'inviteAgain',
      label: (
        <KBFlex
          gap={8}
          align="center"
          onClick={() => onEditVisitor && onEditVisitor(visitor, true)}
        >
          <KBIcon name="file-pen"></KBIcon>
          <KBButton className="tw-h-fit tw-p-0" type="text">
            {tr('common.inviteAgain')}
          </KBButton>
        </KBFlex>
      ),
    })
  }

  if (
    visitor.unified_status !== VisitorUnifiedStatusEnum.signed_out &&
    visitor.unified_status !== VisitorUnifiedStatusEnum.cancelled
  ) {
    actionItems.push({
      key: 'Cancel',
      label: (
        <KBFlex gap={8} align="center">
          <KBIcon name="trash-2" className="tw-text-red-500" />
          {visitor?.is_recurrent ? (
            <KBDropdown
              menu={{
                items: recurrentTypes,
                onClick: (e) => {
                  // FIXME:周期性访客取消 需绑接口
                  console.log(e.key)
                },
              }}
            >
              <KBButton className="tw-h-fit tw-p-0" type="text">
                <KBText className="tw-text-red-500">
                  {tr('common.cancelInvite')}
                </KBText>
              </KBButton>
            </KBDropdown>
          ) : (
            <KBButton
              className="tw-h-fit tw-p-0"
              type="text"
              onClick={() => {
                NiceModal.show('visitorCancelModal', {
                  visitor,
                  refetch,
                })
              }}
            >
              <KBText className="tw-text-red-500">
                {tr('common.cancelInvite')}
              </KBText>
            </KBButton>
          )}
        </KBFlex>
      ),
    })
  }

  if (visitor.unified_status === VisitorUnifiedStatusEnum.signed_in) {
    actionItems.push({
      key: 'signOut',
      label: (
        <KBFlex
          gap={8}
          align="center"
          onClick={() => {
            signOutVisitor(
              {
                params: {
                  id: visitor?.id,
                },
              },
              {
                onSuccess: () => {
                  kbMessage.success(tr('common.signOutSuccess'))
                  refetch?.()
                },
              }
            )
          }}
        >
          <KBIcon name="circle-x"></KBIcon>
          <KBButton className="tw-h-fit tw-p-0" type="text">
            {tr('common.signOut')}
          </KBButton>
        </KBFlex>
      ),
    })
  } else if (visitor.unified_status === VisitorUnifiedStatusEnum.approving) {
    if (!isUserClient() || isUserClientApprovalAndReject) {
      actionItems.push({
        key: 'Reject',
        label: (
          <KBFlex
            gap={8}
            align="center"
            onClick={() => onRejectVisitor && onRejectVisitor(visitor)}
          >
            <KBIcon name="circle-x" />
            <KBButton className="tw-h-fit tw-p-0" type="text">
              {tr('common.approvalRejected')}
            </KBButton>
          </KBFlex>
        ),
      })
      actionItems.push({
        key: 'Approve',
        label: (
          <KBFlex gap={8} align="center" onClick={() => handleApprove()}>
            <KBIcon name="circle-check" />
            <KBButton className="tw-h-fit tw-p-0" type="text">
              {tr('common.approved')}
            </KBButton>
          </KBFlex>
        ),
      })
    }
  } else if (
    visitor.unified_status === VisitorUnifiedStatusEnum.waiting_for_sign
  ) {
    actionItems.push({
      key: 'signIn',
      label: (
        <KBFlex
          gap={8}
          align="center"
          onClick={() => {
            signInVisitor(
              {
                params: {
                  id: visitor?.id,
                },
                // data: {
                //   unified_status: VisitorUnifiedStatusEnum.signed_in,
                // },
              },
              {
                onSuccess: () => {
                  kbMessage.success(tr('common.signInSuccess'))
                  refetch?.()
                },
              }
            )
          }}
        >
          <KBIcon name="circle-check"></KBIcon>
          <KBButton className="tw-h-fit tw-p-0" type="text">
            {tr('common.signIn')}
          </KBButton>
        </KBFlex>
      ),
    })
  }

  const gotoVisitorInfo = () => {
    if (readonly) return
    if (isUserClient()) {
      navigate(`${visitor?.id}`)
    } else {
      navigate(`/admin/locations/${currentLocationId!}/visitors/${visitor?.id}`)
    }
  }

  return (
    <KBFlex
      justify="space-between"
      gap={16}
      className="tw-box-border tw-cursor-default tw-border tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-gray-200 tw-px-2 tw-py-3 hover:tw-cursor-pointer hover:tw-bg-gray-50"
      onClick={gotoVisitorInfo}
    >
      <KBFlex
        gap={8}
        align="center"
        className="tw-shrink tw-basis-[75%] tw-overflow-hidden"
      >
        <KBText className="tw-flex-shrink-0">
          {visitor?.expect_arrival_date
            ? formatDateMonthDay(visitor?.expect_arrival_date)
            : ''}
        </KBText>

        {/* 外边包一层div 阻止click冒泡 */}
        <div
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <KBDropdown
            trigger={['click']}
            placement="bottom"
            menu={{
              items: actionItems,
            }}
          >
            <a href="#">
              <VisitorStateIcon
                visitorState={visitor.unified_status}
                showTooltip
              />
            </a>
          </KBDropdown>
        </div>

        {/* <KBEnumTag
          enums={VisitorUnifiedStatusEnumObjects}
          value={visitor?.unified_status!}
          modelName="VisitorUnifiedStatusEnum"
        /> */}
        <KBTag className="tw-flex-shrink-0">
          <KBText level={3}>{visitor?.visitor_type?.name}</KBText>
        </KBTag>
        <KBUser
          className="tw-flex-shrink-0"
          user={visitor?.visitor_user}
          showName={true}
          showTooltip
        />

        <KBText ellipsis={true}>{visitor?.note}</KBText>
      </KBFlex>
      <KBFlex gap={8} align="center">
        {isUserClient() && (
          <KBFlex
            align="center"
            gap={4}
            className="tw-rounded-full tw-border tw-border-solid tw-border-gray-200 tw-px-2 tw-py-1"
          >
            <KBIcon
              name="building"
              className="tw-text-[var(--wox-color-primary)]"
            />
            <KBText level={3}>{visitor?.location?.name}</KBText>
          </KBFlex>
        )}
        <KBTooltip title={tr('common.expectedVisitTime')}>
          <KBText level={3} className="tw-text-gray-600">
            {formatTime(visitor?.expect_visit_at!)}
          </KBText>
        </KBTooltip>
        {visitor?.unified_status === VisitorUnifiedStatusEnum.signed_in && (
          <>
            <KBTooltip title={tr('common.checkInTime')}>
              <KBText level={3} className="tw-text-gray-600">
                {formatTime(visitor?.signed_in_at!)}
              </KBText>
            </KBTooltip>
          </>
        )}

        {visitor?.unified_status === VisitorUnifiedStatusEnum.signed_out && (
          <>
            <KBTooltip title={tr('common.checkOutTime')}>
              <KBText level={3} className="tw-text-gray-600">
                {formatTime(visitor?.signed_out_at!)}
              </KBText>
            </KBTooltip>
          </>
        )}

        <KBUser
          user={visitor?.host_user}
          className="tw-ml-0.5 tw-flex-shrink-0"
          showTooltip
        />

        <KBTooltip title={tr('common.view')}>
          <KBButton
            type="text"
            icon={<KBIcon name="eye" />}
            onClick={gotoVisitorInfo}
            className="tw-w-8 tw-px-0"
          />
        </KBTooltip>

        {!readonly && (
          <KBFlex
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <KBDropdown
              trigger={['click']}
              placement="bottom"
              menu={{
                items: actionItems,
              }}
            >
              <KBButton
                type="text"
                className="tw-w-8 tw-px-0"
                icon={<KBIcon name="ellipsis" />}
              />
            </KBDropdown>
          </KBFlex>
        )}
      </KBFlex>
    </KBFlex>
  )
}

export default KBVisitorListItem
