import { DatePicker, DatePickerProps } from 'antd'

import { cn } from '@/utils/tailwind.helper'
import { formatStorage } from '@wox/dayjs'

export interface KBDatePickerProps extends DatePickerProps {}

export function KBDatePicker(props: KBDatePickerProps) {
  const { size = 'large', className, ...others } = props
  const dateFormat = formatStorage.get()?.dateFormat || 'YYYY-MM-DD'
  return (
    <DatePicker
      size={size}
      data-testid="date-picker"
      inputReadOnly // Prevent keyboard input
      format={dateFormat}
      {...others}
      className={cn(size === 'large' && 'tw-h-9', 'kb-date-picker', className)}
    />
  )
}

KBDatePicker.RangePicker = DatePicker.RangePicker
