import NiceModal, { useModal } from '@ebay/nice-modal-react'
import dayjs, { Dayjs } from '@wox/dayjs'

import {
  KBDatePicker,
  KBFlex,
  KBInputNumber,
  KBModal,
  KBSelect,
  KBText,
} from '@/components/atoms'
import KBRecurrenceByMonth from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceByMonth'
import KBRecurrenceByWeek from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceByWeek'
import KBRecurrenceByYear from '@/components/organisms/KBRecurrenceEditModal/KBRecurrenceByYear'
import { pick } from 'radash'
import {
  getRecurrenceDescription,
  RecurrenceByMonthInterval,
  RecurrenceByYearInterval,
  RecurrenceInterval,
} from './constants'

export interface RecurrenceInfo {
  /**
   * @description 重复类型, 用于回显
   */
  intervalType: RecurrenceInterval
  /**
   * @description 重复间隔
   */
  interval?: number
  /**
   * @description 开始时间
   */
  startDate: Dayjs
  /**
   * @description 结束时间
   */
  endDate: Dayjs
  /**
   * @description 按周预定，选择的周几
   */
  selectedWeekdays?: number[]
  /**
   * @description 按月/年预定，每月几号
   */
  monthDay?: number
  /**
   * @description 按月/年预定，第几周
   */
  monthWeekNumber?: number
  /**
   * @description 按月/年预定，周几
   */
  monthWeekDay?: number
  /**
   * @description 按年预定，几月
   */
  month?: number
  /**
   * @description 按月预定，重复方式
   */
  monthRecurrenceByMonthInterval?: RecurrenceByMonthInterval
  /**
   * @description 按年预定，重复方式
   */
  yearRecurrenceByYearInterval?: RecurrenceByYearInterval
}

export interface KBRecurrenceEditModalProps {
  intervalType?: RecurrenceInterval
  startTime?: Dayjs
  endTime?: Dayjs
  recurrenceInfo?: RecurrenceInfo
  onConfirm?: (info: RecurrenceInfo) => void
}

function KBRecurrenceEditModal(props: KBRecurrenceEditModalProps) {
  const {
    intervalType = RecurrenceInterval.day,
    startTime = dayjs(),
    endTime = dayjs().startOf('month').add(1, 'M'), // FIXME: 每种类型的结束时间是不是不一样？
    onConfirm,
  } = props
  const modal = useModal()
  const recurrenceIntervalOptions = [
    { label: tr('common.day'), value: RecurrenceInterval.day },
    { label: tr('common.week'), value: RecurrenceInterval.week },
    { label: tr('common.month'), value: RecurrenceInterval.month },
    // 暂时去掉按年预定
    // { label: tr('common.year'), value: RecurrenceInterval.year },
  ]

  const defaultRecurrenceInfo = {
    intervalType: intervalType || RecurrenceInterval.noRecurrence,
    startDate: startTime,
    endDate: endTime,
    interval: 1,
  }
  const [recurrenceInfo, setRecurrenceInfo] = useState<RecurrenceInfo>(
    props.recurrenceInfo || defaultRecurrenceInfo
  )

  // useEffect(() => {
  //   setRecurrenceInfo({
  //     ...defaultRecurrenceInfo,
  //     ...props.recurrenceInfo,
  //     startDate: startTime,
  //     endDate: endTime,
  //   })
  // }, [props.recurrenceInfo, startTime, endTime, intervalType])

  function onOk() {
    onConfirm?.(recurrenceInfo)
    modal.hide()
  }

  console.log('>>> recurrenceInfo', recurrenceInfo)

  return (
    <KBModal
      width={512}
      title={tr('recurrence.edit')}
      modal={modal}
      onOk={onOk}
    >
      <div>
        <KBFlex align="center" gap={8}>
          <KBText className="tw-w-20 tw-flex-shrink-0">
            {tr('reservation.recurrenceInterval')}
          </KBText>
          {recurrenceInfo.intervalType !== RecurrenceInterval.year && (
            <KBInputNumber
              className="tw-flex-1"
              defaultValue={recurrenceInfo.interval}
              onChange={(value) =>
                setRecurrenceInfo({
                  ...recurrenceInfo,
                  interval: value as number,
                })
              }
            />
          )}

          <KBSelect
            options={recurrenceIntervalOptions}
            className={
              recurrenceInfo.intervalType === RecurrenceInterval.year
                ? 'tw-w-full'
                : 'tw-w-20'
            }
            defaultValue={recurrenceInfo.intervalType}
            onChange={(value: RecurrenceInterval) => {
              if (value === RecurrenceInterval.day) {
                setRecurrenceInfo({
                  ...pick(recurrenceInfo, ['startDate', 'endDate', 'interval']),
                  intervalType: value,
                })
              } else if (value === RecurrenceInterval.week) {
                setRecurrenceInfo({
                  ...pick(recurrenceInfo, ['startDate', 'endDate', 'interval']),
                  selectedWeekdays: [dayjs().weekday()],
                  intervalType: value,
                })
              } else if (value === RecurrenceInterval.month) {
                setRecurrenceInfo({
                  ...pick(recurrenceInfo, ['startDate', 'endDate', 'interval']),
                  monthRecurrenceByMonthInterval: RecurrenceByMonthInterval.day,
                  monthDay: dayjs().date(),
                  intervalType: value,
                })
              } else if (value === RecurrenceInterval.year) {
                setRecurrenceInfo({
                  ...pick(recurrenceInfo, ['startDate', 'endDate']),
                  interval: 1,
                  yearRecurrenceByYearInterval: RecurrenceByYearInterval.day,
                  monthDay: dayjs().date(),
                  // monthWeekNumber: 1,
                  month: dayjs().month() + 1,
                  // monthWeekDay: dayjs().weekday(),
                  // ...recurrenceInfo,
                  intervalType: value,
                })
              }
            }}
          />
        </KBFlex>

        {/* 按周重复 */}
        {recurrenceInfo.intervalType === RecurrenceInterval.week && (
          <KBRecurrenceByWeek
            {...props}
            recurrenceInfo={recurrenceInfo}
            setRecurrenceInfo={setRecurrenceInfo}
          />
        )}

        {/* 按月重复 */}
        {recurrenceInfo.intervalType === RecurrenceInterval.month && (
          <KBRecurrenceByMonth
            {...props}
            recurrenceInfo={recurrenceInfo}
            setRecurrenceInfo={setRecurrenceInfo}
          />
        )}

        {/* 按年预定 */}
        {recurrenceInfo.intervalType === RecurrenceInterval.year && (
          <KBRecurrenceByYear
            {...props}
            recurrenceInfo={recurrenceInfo}
            setRecurrenceInfo={setRecurrenceInfo}
          />
        )}

        <KBFlex align="center" gap={8} className="tw-my-6">
          <KBText className=" tw-w-20">{tr('recurrence.endDate')}</KBText>
          <KBDatePicker
            className="tw-flex-1"
            defaultValue={recurrenceInfo.endDate}
            onChange={(value) =>
              setRecurrenceInfo({ ...recurrenceInfo, endDate: value })
            }
          />
        </KBFlex>
        <KBText className="tw-text-[var(--wox-color-text-description)]">
          {getRecurrenceDescription(recurrenceInfo)}
        </KBText>
      </div>
    </KBModal>
  )
}

export default NiceModal.create(KBRecurrenceEditModal)
