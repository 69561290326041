import { KBFlex, KBText } from '@/components/atoms'
import { cn } from '@/utils/tailwind.helper'

export type KBEmptySize = 'small' | 'large'
export interface KBEmptyProps {
  title?: string
  /**
   * 是否为行内展示，显示
   */
  size?: KBEmptySize
}

export const KBEmpty = (props: KBEmptyProps) => {
  const { title = tr('common.noData'), size = 'large' } = props
  return (
    <KBFlex
      vertical
      justify="center"
      align="center"
      className={cn('tw-m-auto', size === 'large' ? 'tw-px-8 tw-py-10' : '')}
    >
      {size === 'large' && <img src="/empty/empty.svg" alt="empty" />}
      <KBText level={1} weight="bigger">
        {title}
      </KBText>
    </KBFlex>
  )
}
