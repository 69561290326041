import { ESSENTIAL_TIME_ZONES, Timezone } from '@wox/dayjs'
import React from 'react'

import { KBSelect, KBSelectProps } from '@/components/atoms'

interface KBTimezoneSelectProps extends KBSelectProps {
  // labelStyle?: 'original' | 'altName' | 'abbrev' | 'offsetHidden'
  // displayValue?: 'GMT' | 'UTC'
}

const KBTimezoneSelect: React.FC<KBTimezoneSelectProps> = (props) => {
  const { value, onChange, ...restProps } = props
  // default timezone
  const [timezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)

  const options = useMemo(
    () =>
      ESSENTIAL_TIME_ZONES.map((timezone: Timezone) => ({
        value: JSON.stringify(timezone),
        label: `${timezone.value} (UTC${timezone.offset}) ${timezone.altName}`,
      })),
    []
  )

  return (
    <KBSelect
      defaultValue={timezone}
      {...restProps}
      value={value}
      options={options}
      showSearchInput
      searchFn={(option, text) => {
        if (text) {
          return (
            String(option?.value).toLowerCase()?.includes(text.toLowerCase()) ||
            String(option?.label).toLowerCase()?.includes(text.toLowerCase())
          )
        }
        return false
      }}
      dropdownStyle={{
        width: 420,
      }}
      showSearch={false}
      onChange={(value, option) => {
        if (onChange) {
          const timezone = JSON.parse(value)
          onChange(timezone, option)
        }
      }}
    />
  )
}

export default KBTimezoneSelect
