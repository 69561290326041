import React from 'react'

import {
  KBButton,
  KBFlex,
  KBIcon,
  KBText,
  KBTypography,
} from '@/components/atoms'
const { Title, Text, Link } = KBTypography

import { AuthUserEntity, useAuthFirstLogin } from '@/api'
import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import { tr } from '@/utils/localization/tr'
import WoxIconLogo from '~icons/wox-icons/woxLogo.svg'

const AuthFirstLogin: React.FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const authUser = state?.user as AuthUserEntity

  const { mutate: firstLogin, isPending } = useAuthFirstLogin()

  useEffect(() => {
    firstLogin({
      data: {
        email: authUser.email!,
        space_id: authUser.space?.id!,
      },
    })
  }, [])

  if (!authUser) {
    navigate('/login')
    return null
  }

  return (
    <KBFlex
      vertical
      gap={32}
      justify="center"
      align="center"
      className="tw-m-auto"
    >
      <KBFlex align="center" gap={24} vertical>
        <WoxIconLogo />
      </KBFlex>
      <KBFlex
        vertical
        justify="center"
        align="center"
        className="tw-m-auto tw-w-[550px] tw-rounded-lg  tw-p-10"
      >
        <KBFlex vertical align="center" gap={20}>
          {!isPending ? (
            <>
              <KBFlex
                justify="center"
                align="center"
                className="tw-h-[48px] tw-w-[48px] tw-rounded-full tw-bg-red-100"
              >
                <KBIcon name="triangle-alert" className="tw-text-orange-600" />
              </KBFlex>
              <KBFlex vertical align="center" gap={8}>
                <Title level={5} className="tw-m-0">
                  {tr('login.firstLoginTitle')}
                </Title>
                <KBText className="tw-text-gray-500">
                  {trHTML('login.firstLoginDescription', {
                    email: authUser.email!,
                  })}
                </KBText>
                <KBButton
                  type="primary"
                  onClick={() => window.location.assign('/login')}
                >
                  {tr('common.gotIt')}
                </KBButton>
              </KBFlex>
            </>
          ) : (
            <KBSuspenseLoading />
          )}
        </KBFlex>
      </KBFlex>
    </KBFlex>
  )
}

export default AuthFirstLogin
